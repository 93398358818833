<template>
    <div
        class="menubar"
        :class="{
            mobile_button: openPercentage > 0,
        }"
    >
        <div class="menubar__title_container">
            <h1 class="menubar__title">{{ $t(locationName) }}</h1>
        </div>
        <MobileMenuButton v-if="openPercentage === 0" v-on:openMenu="open" />
        <UserHeaderButton v-if="openPercentage > 0" />
        <div v-else class="menubar__spacer"></div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import animateXtoY from "@feedbackcompany/feedback-company-vue-components/src/helpers/animationHelper";
import useLocationName from "@/helpers/useLocationName";
import MobileMenuButton from "@/components/Layout/Atoms/MobileMenuButton.vue";
import UserHeaderButton from "@/components/Layout/Atoms/UserHeaderButton.vue";

export default {
    name: "MobileMenubar",
    components: {
        MobileMenuButton,
        UserHeaderButton,
    },
    data() {
        return {
            locationName: "",
        };
    },
    computed: {
        ...mapState({
            storeOpenPercentage: (state) => state.navigation.openPercentage,
        }),
        showInboxNavigation() {
            return this.$route.name === "InboxDetail" || this.$route.params.ID !== null;
        },
        openPercentage() {
            let openPercentage = this.storeOpenPercentage;
            if (this.windowWidth > 1000) openPercentage = 1;
            return openPercentage;
        },
    },
    methods: {
        open() {
            animateXtoY({
                x: 0,
                y: 1,
                time: 320,
                easeName: "easeOutCubic",
                executable: ({ setTo }) => {
                    this.$store.commit("navigation", {
                        openPercentage: setTo,
                    });
                },
            });
        },
    },
    updated() {
        this.locationName = useLocationName(this.$router);
    },
    mounted() {
        this.locationName = useLocationName(this.$router);
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.menubar {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 4;
    background: $white;
    border-bottom: 1px solid $grey_alabaster;
}
.menubar__spacer {
    width: 64px;
    height: 48px;
}
.menubar__title_container {
    position: absolute;
    width: 100%;
    max-height: 100%;
    padding: 2px 0;
    z-index: 8;
}
.menubar__title {
    @extend %body2_style;
    margin: 0;
    text-align: center;
    font-weight: 700;
    padding-bottom: 2px;
    line-height: 1.3;
}
.menubar__inbox_navigation {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 12;
    display: flex;
}
.mobile_button {
    padding-left: 19px;
}
</style>
