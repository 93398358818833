// eslint-disable-next-line func-names
export default function () {
    const state = {
        enabled: false,
        encryptedWidgetId: null,
        platform: null,
        lang: null,
    };

    if (!window) {
        return state;
    }

    const searchParams = new URLSearchParams(window.location.search);

    if (
        searchParams.has("dashboardExportMode") &&
        searchParams.has("encryptedWidgetId") &&
        searchParams.has("platform") &&
        searchParams.has("lang")
    ) {
        state.enabled = Boolean(searchParams.get("dashboardExportMode"));
        state.encryptedWidgetId = searchParams.get("encryptedWidgetId");
        state.platform = searchParams.get("platform");
        state.lang = searchParams.get("lang");
    }

    return state;
}
