<template>
    <ChartExport :encryptedWidgetId="encryptedWidgetId" :platform="platform" />
</template>

<script>
import ChartExport from "@/components/Evaluation/Dashboards/ChartExport.vue";
import useDashboardExportMode from "@/helpers/evaluation/useDashboardExportMode";

export default {
    name: "DashboardExport",
    components: { ChartExport },
    data() {
        const dashboardExportMode = useDashboardExportMode();
        this.$i18n.locale = dashboardExportMode.lang;
        return {
            encryptedWidgetId: dashboardExportMode.encryptedWidgetId,
            platform: dashboardExportMode.platform,
        };
    },
};
</script>
