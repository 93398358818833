import Vue from "vue";
import VueApollo from "vue-apollo";
import { setContext } from "@apollo/client/link/context";
// eslint-disable-next-line import/no-extraneous-dependencies
import { createApolloClient } from "vue-cli-plugin-apollo/graphql-client";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { resolveGraphQLErrors } from "@/helpers/resolveGraphQLErrors";
// eslint-disable-next-line import/no-extraneous-dependencies
import { InMemoryCache } from "apollo-cache-inmemory";

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = "apollo-token";

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_URL;

// Files URL root
export const filesRoot =
    process.env.VUE_APP_FILES_ROOT || httpEndpoint.substr(0, httpEndpoint.indexOf("/graphql"));

Vue.prototype.$filesRoot = filesRoot;

// Config
const defaultOptions = {
    // You can use `https` for secure connection (recommended in production)
    httpEndpoint,
    // You can use `wss` for secure connection (recommended in production)
    // Use `null` to disable subscriptions
    wsEndpoint: null,
    // LocalStorage token
    tokenName: AUTH_TOKEN,
    // Enable Automatic Query persisting with Apollo Engine
    persisting: false,
    // Use websockets for everything (no HTTP)
    // You need to pass a `wsEndpoint` for this to work
    websocketsOnly: false,
    // Is being rendered on the server?
    ssr: false,

    // Override default apollo link
    // note: don't override httpLink here, specify httpLink options in the
    // httpLinkOptions property of defaultOptions.
    // link: httpLink,

    // Override default cache
    cache: new InMemoryCache({
        freezeResults: false,
    }),

    // Override the way the Authorization header is set
    // getAuth: (tokenName) => ...

    // Additional ApolloClient options
    // apollo: { ... }

    // Client local data (see apollo-link-state)
    // clientState: { resolvers: { ... }, defaults: { ... } }
};

// Call this in the Vue app file
export function createProvider(options = {}) {
    const errorLink = onError((errors) => {
        const error = resolveGraphQLErrors(errors);
        if (error && error.includes("FORBIDDEN")) {
            options.router.push("/403");
        }
    });

    const uploadLink = createUploadLink({
        uri: httpEndpoint,
        credentials: "include",
    });

    // eslint-disable-next-line no-unused-vars
    const authLink = setContext((_, { headers }) => {
        if (options.vuexStore?.state.AccountStore.keycloak.isTokenExpired(5)) {
            options.vuexStore.state.AccountStore.keycloak.logout();
        }

        const token = localStorage.getItem("accessToken");

        const evHeaders = {};
        if (options.vuexStore?.state.AccountStore.user?.platform) {
            evHeaders["X-Tevreden-Host"] = options.vuexStore.state.AccountStore.user.platform;
        }

        return {
            headers: {
                ...headers,
                ...evHeaders,
                Authorization: `Bearer ${token}`,
            },
        };
    });
    const clientOptions = {
        link: authLink.concat(errorLink).concat(uploadLink),
        defaultHttpLink: false,
    };
    const { apolloClient } = createApolloClient({
        ...defaultOptions,
        ...clientOptions,
        ...options,
    });

    const vueApolloOptions = {
        clients: {
            default: apolloClient,
        },
        defaultClient: apolloClient,
        defaultOptions: {
            $query: {
                // fetchPolicy: 'cache-and-network',
            },
        },
        errorHandler(error) {
            // eslint-disable-next-line no-console
            console.log(
                "%cError",
                "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
                error.message
            );
        },
    };

    // Set the mock server in dev
    if (process.env.NODE_ENV === "development") {
        // nodejs apollo server config
        const mockClientOptions = { httpEndpoint: "http://localhost:4000/graphql" };
        const mockApolloClient = createApolloClient({
            ...defaultOptions,
            ...mockClientOptions,
            ...options,
        });
        vueApolloOptions.clients.mock = mockApolloClient.apolloClient;

        // Local java gql server config
        const localUploadLink = createUploadLink({
            uri: "http://localhost:8080",
            credentials: "include",
        });
        const localGraphQLClientOptions = {
            httpEndpoint: "http://localhost:8080/graphql",
            link: authLink.concat(localUploadLink),
        };
        const localGraphQLApolloClient = createApolloClient({
            ...defaultOptions,
            ...localGraphQLClientOptions,
            ...options,
            defaultHttpLink: false,
        });
        vueApolloOptions.clients.local = localGraphQLApolloClient.apolloClient;
    }

    // setup strapi client
    const strapiAuthLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                Authorization: `Bearer ${process.env.VUE_APP_STRAPI_KEY}`,
            },
        };
    });

    const strapiClientOptions = {
        httpEndpoint: process.env.VUE_APP_STRAPI_URL,
        link: strapiAuthLink,
    };

    const strapiGraphQLApolloClient = createApolloClient({
        ...defaultOptions,
        ...strapiClientOptions,
    });

    vueApolloOptions.clients.strapi = strapiGraphQLApolloClient.apolloClient;

    return new VueApollo(vueApolloOptions);
}
