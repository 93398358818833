<template>
    <div class="stars">
        <div v-for="(star, index) in starArray" :key="index" class="star">
            <!-- eslint-disable max-len -->
            <svg
                v-if="star === 'empty'"
                :width="svgDimensions"
                :height="svgDimensions"
                viewBox="0 0 33 32"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
            >
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g
                        id="Atom/Rating/Good"
                        transform="translate(-151.000000, 0.000000)"
                        fill="#E5E5EA"
                        fill-rule="nonzero"
                    >
                        <g id="Atom/Icons/Star" transform="translate(151.432931, 0.000000)">
                            <polygon
                                id="Star-Shape"
                                points="26.1533423 32 22.3185998 19.7913596 32 12.1756098 19.9051657 12.1756098 16 0 12.0948343 12.1756098 1.38777878e-17 12.1756098 9.68140023 19.7913596 5.84665771 32 16 24.6025385"
                            ></polygon>
                        </g>
                    </g>
                </g>
            </svg>
            <svg
                v-if="star === 'half'"
                :width="svgDimensions"
                :height="svgDimensions"
                viewBox="0 0 33 32"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
            >
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g
                        id="Atom/Rating/Good"
                        transform="translate(-113.000000, 0.000000)"
                        fill-rule="nonzero"
                    >
                        <g id="Group-3" transform="translate(113.714524, 0.000000)">
                            <polygon
                                id="Star-Shape"
                                fill="#FECD58"
                                points="26.1533423 32 22.3185998 19.7913596 32 12.1756098 19.9051657 12.1756098 16 0 12.0948343 12.1756098 1.38777878e-17 12.1756098 9.68140023 19.7913596 5.84665771 32 16 24.6025385"
                            ></polygon>
                            <polygon
                                id="Star-Shape-half"
                                fill="#E5E5EA"
                                points="26.1533423 32 22.3185998 19.7913596 32 12.1756098 19.9051657 12.1756098 16 0 16 24.6025385"
                            ></polygon>
                        </g>
                    </g>
                </g>
            </svg>
            <svg
                v-if="star === 'full'"
                :width="svgDimensions"
                :height="svgDimensions"
                viewBox="0 0 32 32"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
            >
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Atom/Rating/Good" fill="#FECD58" fill-rule="nonzero">
                        <polygon
                            id="Star-Shape"
                            points="26.1533423 32 22.3185998 19.7913596 32 12.1756098 19.9051657 12.1756098 16 0 12.0948343 12.1756098 1.38777878e-17 12.1756098 9.68140023 19.7913596 5.84665771 32 16 24.6025385"
                        ></polygon>
                    </g>
                </g>
            </svg>
            <!-- eslint-enable max-len -->
        </div>
    </div>
</template>

<script>
export default {
    name: "Stars",
    props: {
        rating: {
            required: true,
        },
        size: {
            type: Number,
            default: 48,
        },
    },
    computed: {
        svgDimensions() {
            return `${this.size}px`;
        },
        starArray() {
            const starArray = [];
            const amountOfStars = this.rating / 2;
            for (let index = 1; index < 6; index += 1) {
                let modifier = "empty";
                if (index < amountOfStars || index === amountOfStars) {
                    modifier = "full";
                } else if (index === amountOfStars + 0.5) {
                    modifier = "half";
                }
                starArray.push(modifier);
            }
            return starArray;
        },
    },
};
</script>

<style>
.stars {
    display: inline-block;
}
.stars > *:not(:last-child) {
    margin-right: 2px;
}
.star {
    display: inline;
}
</style>
