<template>
    <div>
        <h4 class="title">Dashboards</h4>
        <SearchBar
            v-if="searchIsEnabled"
            class="search-bar"
            :value="searchQuery"
            @input="searchQuery = $event"
            :placeholder="$t('cp__respondents_list_search')"
        />
        <ul class="list">
            <li v-for="(dashboard, key) in dashboards" :key="key" class="list-item">
                <button
                    class="link"
                    :class="{ isActive: dashboard.id === $route.params.id }"
                    @click="onDashboardClick(dashboard)"
                    v-line-clamp:32="1"
                >
                    {{ dashboard.name }}
                </button>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState } from "vuex";
import SearchBar from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/SearchBar.vue";
import animateXtoY from "@feedbackcompany/feedback-company-vue-components/src/helpers/animationHelper";

export default {
    name: "DashboardsList",
    components: { SearchBar },
    data() {
        return {
            searchQuery: "",
        };
    },
    computed: {
        ...mapState({
            availableDashboards: (state) => state.AccountStore.dashboards,
            subNavigationTrayOpenPercentage: (state) =>
                state.AccountStore.subNavigationTrayOpenPercentage,
        }),
        dashboards() {
            if (!this.searchIsEnabled) return this.availableDashboards;
            if (this.searchQuery === "") return this.availableDashboards;

            const normalise = (string) => string.trim().toLowerCase();
            return this.availableDashboards.filter((dashboard) =>
                normalise(dashboard.name).includes(normalise(this.searchQuery))
            );
        },
        searchIsEnabled() {
            return this.availableDashboards.length >= 10;
        },
    },
    methods: {
        onDashboardClick(dashboard) {
            this.$router.push(`/dashboards/${dashboard.id}?type=${dashboard.type}`);
            animateXtoY({
                x: this.subNavigationTrayOpenPercentage,
                y: 0,
                time: 200,
                easeName: "easeOutQuad",
                executable: ({ setTo }) => {
                    this.$store.commit("AccountStore/subNavigationTrayOpenPercentage", {
                        openPercentage: setTo,
                    });
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~include-media";
@import "../../style_variables/style_variables.scss";

.title {
    font-family: "Montserrat", sans-serif;
    margin: 0 0 20px 0;
    font-size: 1rem;
    font-weight: 700;
}

.list {
    padding: 0;
    list-style-type: none;

    @include media($isDesktop...) {
        max-height: calc(100vh - 212px);
        overflow-y: auto;
    }

    .list-item {
        @extend %body2_style;
        padding: 0;
        line-height: 32px;

        .link {
            @extend %subtitle2_style;
            width: 100%;
            text-decoration: none;
            user-select: none;
            cursor: pointer;
            padding: 4px 0;
            width: 100%;

            &.isActive {
                color: $blue;
            }
        }
    }
}

.search-bar {
    width: 100%;
    margin-bottom: 16px;
}
</style>
