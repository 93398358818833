import LESExtension from "@/stores/store_extensions/LESExtension";

const base = LESExtension({
    loadables: [
        {
            name: "reviseText",
            endpoint: "/api/v3/Review/getReviseEmailText",
        },
    ],
});

export default {
    namespaced: true,
    state: {
        ...base.state,
    },
    mutations: {
        ...base.mutations,
    },
    actions: {
        ...base.actions,
    },
};
