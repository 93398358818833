import TypeExtension from "./store_extensions/StoreTypeExtension";

const baseStore = TypeExtension({
    get: {
        url: `${process.env.VUE_APP_API_BASE_URL}/api/v3/review/getProductReviewDetail`,
    },
    reply: {
        url: `${process.env.VUE_APP_API_BASE_URL}/api/v3/review/productReviewReply`,
    },
    setRead: {
        url: `${process.env.VUE_APP_API_BASE_URL}/api/v3/review/setProductReviewIsRead`,
    },
});

export default {
    ...baseStore,
};
