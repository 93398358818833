<template>
    <div class="container">
        <div class="round">
            <input
                :disabled="disabled"
                class="selection"
                type="checkbox"
                :id="'checkbox_' + id"
                :checked="inputValue"
                @change="onChange($event)"
            />
            <label @click.stop :for="'checkbox_' + id"></label>
        </div>
    </div>
</template>
<script>
import InputBase from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/mixins/InputBase";

export default {
    name: "SelectedCheckbox",
    mixins: [InputBase],
    props: {
        id: {
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
    },
    methods: {
        onChange(event) {
            this.inputValue = event.target.checked;
            this.$emit("change", this.inputValue);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.selection {
    -webkit-appearance: auto;
}

.round {
    position: relative;
}

.round label {
    background-color: #fff;
    border: 2px solid $grey_french;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 18px;
    height: 18px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 4px;
    left: 2px;
    opacity: 0;
    position: absolute;
    top: 3px;
    transform: rotate(-45deg);
    width: 8px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
    background-color: $blue;
    border-color: $blue;
}

.round input[type="checkbox"]:disabled + label {
    background-color: $grey_french;
    border-color: $grey_french;
}

.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
}
</style>
