var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.selectState ? 'div' : 'router-link',{tag:"router-link",staticClass:"inbox_item",class:{
        'inbox_item--unread': !_vm.read,
        'inbox_item--selected': _vm.selected,
    },attrs:{"tag":"li","tabindex":"0","to":{
        name: 'InboxDetail',
        params: { page: _vm.page, type: _vm.type, ID: _vm.ID },
        query: Object.assign({}, _vm.$route.query, {chunkIndex: _vm.page}),
    }}},[_c('div',{staticClass:"inbox_item_container",on:{"click":function($event){_vm.selectState && !_vm.areAllSelected
                ? _vm.selectOrUnselectReview({ selection: !_vm.isSelected, id: _vm.ID })
                : ''}}},[(_vm.selectState)?_c('SelectedCheckbox',{staticClass:"selected_checkbox",attrs:{"id":_vm.ID,"value":_vm.isSelected,"disabled":_vm.areAllSelected}}):_vm._e(),_c('div',{staticStyle:{"width":"100%"}},[_c('header',{staticClass:"inbox_item__header"},[_c('span',{staticClass:"inbox_item__name",attrs:{"title":_vm.consumerName}},[_vm._v(_vm._s(_vm.consumerName))]),_c('time',[_c('TimeAgo',{staticClass:"inbox_item__date",attrs:{"date":_vm.date}})],1)]),_c('main',{staticClass:"inbox_item__main"},[(_vm.hasStrongTitleContent)?_c('strong',{class:{
                        inbox_item__title: true,
                        'inbox_item__title--unread': !_vm.read,
                        'inbox_item__title--selected': _vm.selected,
                    }},[_vm._t("titleStrong")],2):_vm._e(),_c('span',{directives:[{name:"line-clamp",rawName:"v-line-clamp:2",value:(2),expression:"2",arg:"2"}],class:{
                        inbox_item__excerpt: true,
                        'inbox_item__excerpt--unread': !_vm.read,
                        'inbox_item__excerpt--selected': _vm.selected,
                    }},[_vm._v(" "+_vm._s(_vm.formattedExcerpt)+" ")])]),_c('footer',{staticClass:"inbox_item__footer"},[_c('div',{staticClass:"inbox_item__icons"},[_vm._t("icons"),_vm._t("stars")],2)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }