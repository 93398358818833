<template>
    <span class="time_ago" :title="formattedDate">{{ displayDate }}</span>
</template>

<script>
import { mapState } from "vuex";
import { formatDistance, differenceInDays, format } from "date-fns";
import { enGB, nl, de, es } from "date-fns/locale";

const locales = {
    en: enGB,
    nl,
    de,
    es,
};

const dateFormats = {
    en: "MMM d, yyyy",
    nl: "dd-MM-yyyy",
    es: "dd-MM-yyyy",
    de: "dd-MM-yyyy",
};

export default {
    name: "TimeAgo",
    props: {
        date: {
            required: true,
            type: String,
        },
    },
    computed: {
        ...mapState({
            locale: (state) => state.LanguageStore.currentLanguage,
        }),
        dateAsClass() {
            return new Date(this.date);
        },
        formattedDate() {
            return format(this.dateAsClass, `${dateFormats[this.locale]} HH:mm`);
        },
        displayDate() {
            let displayDate = format(this.dateAsClass, dateFormats[this.locale]);
            if (differenceInDays(new Date(), this.dateAsClass) < 4) {
                displayDate = formatDistance(this.dateAsClass, new Date(), {
                    addSuffix: true,
                    locale: locales[this.locale],
                });
            }
            return displayDate;
        },
    },
};
</script>

<style lang="scss" scoped></style>
