<template>
    <Tray
        :top="68"
        :left="left"
        :open-percentage="notificationTrayOpenPercentage"
        @openPercentageChangeRequest="onOpenPercentageChangeRequest"
    >
        <div class="notification_tray__content">
            <div class="notification_tray__content__headline">
                {{ $t("cp__notification_tray__title") }}
            </div>
            <ul class="notification_types">
                <NotificationButton
                    :to="inboxLink"
                    :count="merchantReviewUnseenCount"
                    :label="$t('cp__notification_tray__unseen_merchant_reviews')"
                    colorScheme="blue"
                >
                    <font-awesome-icon :icon="['fal', 'store']" />
                </NotificationButton>
                <NotificationButton
                    v-if="productReviewsEnabled"
                    to="/inbox/product-reviews"
                    :count="productReviewUnreadCount"
                    :label="
                        $t(
                            'cp__notification_tray__unseen_product_reviews',
                            'Unseen product reviews'
                        )
                    "
                    colorScheme="green"
                >
                    <font-awesome-icon :icon="['fal', 'shopping-bag']" />
                </NotificationButton>
                <NotificationButton
                    v-if="productQuestionsEnabled"
                    to="/inbox/product-questions"
                    :count="productQuestionUnansweredCount"
                    :label="
                        $t('cp__notification_tray__unanswered_questions', 'Unanswered questions')
                    "
                    colorScheme="cyan"
                >
                    <font-awesome-icon :icon="['fal', 'comment-alt-minus']" />
                </NotificationButton>
            </ul>
        </div>
    </Tray>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faStore, faShoppingBag, faCommentAltMinus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Tray from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/Tray.vue";
import NotificationButton from "@/components/Layout/Atoms/NotificationButton.vue";

library.add(faStore, faShoppingBag, faCommentAltMinus);

export default {
    name: "notificationTray",
    data() {
        return {
            left: 0,
        };
    },
    components: {
        NotificationButton,
        FontAwesomeIcon,
        Tray,
    },
    computed: {
        ...mapState({
            merchantReviewUnseenCount: (state) => state.NotificationStore.merchantReviewUnseenCount,
            productReviewUnreadCount: (state) => state.NotificationStore.productReviewUnreadCount,
            productQuestionUnansweredCount: (state) =>
                state.NotificationStore.productQuestionUnansweredCount,
            notificationTrayOpenPercentage: (state) =>
                state.NotificationStore.notificationTrayOpenPercentage,
            productReviewsEnabled: (state) => state.NotificationStore.productReviewsEnabled,
            productQuestionsEnabled: (state) => state.NotificationStore.productQuestionsEnabled,
        }),
        inboxLink() {
            return this.productReviewsEnabled ? "/inbox/" : "/inbox/new/";
        },
    },
    methods: {
        ...mapMutations({
            setNotificationTrayOpenPercentage: "NotificationStore/notificationTrayOpenPercentage",
        }),
        onOpenPercentageChangeRequest({ percentage }) {
            this.setNotificationTrayOpenPercentage({
                openPercentage: percentage,
            });
        },
    },
    watch: {
        notificationTrayOpenPercentage() {
            const bellEl = document.getElementsByClassName("notification_open_button")[0];
            this.left = bellEl.offsetLeft - 132;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.notification_types {
    padding: 0px;
    margin: 0px;
}
.notification_tray__content__headline {
    @extend %headline6_style;
    text-align: center;
    margin: 16px 0 12px;
    @media (min-width: 1001px) {
        display: none;
    }
}
.notification_type {
    @extend %body2_style;
    display: flex;
    align-items: center;
    height: 60px;
    list-style: none;
    padding: 0 12px;
    @media (max-width: 1000px) {
        font-size: 1em;
        height: 96px;
    }
    &:hover {
        cursor: pointer;
        background: darken($white, 2);
    }
}
.notification_type--dark_blue {
    .notification_type__thumbnail {
        background: rgba($blue, 0.1);
        color: $blue;
    }
}
.notification_type--green {
    .notification_type__thumbnail {
        background: rgba($bright_turquoise, 0.1);
        color: $green;
    }
}
.notification_type--cyan {
    .notification_type__thumbnail {
        background: rgba($cyan, 0.1);
        color: $cyan;
    }
}
.notification_type__thumbnail {
    width: 36px;
    height: 36px;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    @media (max-width: 1000px) {
        width: 72px;
        height: 72px;
        border-radius: 8px;
    }
}
.notification_type__thumbnail_icon {
    font-size: 16px;
    @media (max-width: 1000px) {
        font-size: 24px;
    }
}
</style>
