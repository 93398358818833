import queryString from "query-string";
import { version } from "@/../package.json";

export default ({ url, urlParams }) => {
    // Get language to be used
    // Check if the user has set a language with the language toggler,
    // otherwise use the browser language
    let language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);
    // If the browser language isn't one of our supported languages for the Customer Portal use english.
    if (["en", "nl"].indexOf(language) < 0) language = "en";
    // Get param data from url string function parameter
    const paramDataFromURL = queryString.parse(url.split("?")[1]);
    // Merge function param data
    const mergedParams = queryString.stringify({
        ...urlParams,
        ...paramDataFromURL,
        version,
        language,
    });
    // Buildthe URL.
    const requestUrl = `${url.split("?")[0]}?${mergedParams}`;
    return requestUrl;
};
