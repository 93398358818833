// import httpPost from '@/helpers/httpPost';
import Vue from "vue";
import queryString from "query-string";
import * as Sentry from "@sentry/vue";
import httpGet from "@/helpers/httpGet";
import router from "@/router";
// These are used here so we don't have to loop 3 times over the review list.
import InboxItemMerchantReview from "@/components/Inbox/Molecules/InboxItemMerchantReview.vue";
import InboxItemProductReview from "@/components/Inbox/Molecules/InboxItemProductReview.vue";
import InboxItemProductQuestion from "@/components/Inbox/Molecules/InboxItemProductQuestion.vue";
import removeObjectEmptyValues from "@/helpers/removeObjectEmptyValues";
import getDivisionByReviewId from "@/graphql/getDivisionByReviewId.gql";

const config = {
    rowsPerPage: 50,
};

// Objects keep reference otherwise
const getEmptyState = () => ({
    inboxChunks: {},
    filterTrayOpenPercentage: 0,
    responseInputCollapsed: false,
    sorting: {
        searchText: "",
        fromDate: null,
        toDate: null,
        type: null,
        fromRating: 1,
        toRating: 10,
        sortBy: "createDate",
        sortOrder: "DESC",
        isRead: null,
        isReplied: null,
        isRevised: null,
        isPublished: null,
        isAnswered: null,
        hasUploadedImage: null,
    },
    paging: {},
    inboxChunksDisplayed: [],
    loading: true,
    initialLoadDone: false,
    reviewContext: { divisionId: null, divisionUuid: null, inboxPath: null },
    selectState: false,
    areAllSelected: false,
    numerOfSelectedItems: 0,
    numerOfSelectedUnreadItems: 0,
    selectedItems: [],
});

// Get filter params from URL.
const emptyStatePopulated = getEmptyState();
const { query } = queryString.parseUrl(window.location.href);
if (Object.keys(query).length > 0) {
    Object.keys(query).forEach((key) => {
        emptyStatePopulated.sorting[key] = query[key];
    });
}

// Sanitize Sorting Object
const sanitizeSorting = ({ sorting }) => {
    const sortingFiltered = removeObjectEmptyValues(sorting);
    const emptySort = getEmptyState().sorting;
    Object.keys(emptySort).forEach((sortKey) => {
        if (
            (sortingFiltered[sortKey] && sortingFiltered[sortKey] == emptySort[sortKey]) ||
            sortingFiltered[sortKey] === null
        ) {
            delete sortingFiltered[sortKey];
        }
    });
    return sortingFiltered;
};

const findInInboxChunks = ({ ID, inboxChunks, searchPages }) => {
    const result = {
        chunkIndex: null,
        rowIndex: null,
        review: null,
    };

    searchPages.some((searchPage) => {
        if (!inboxChunks[searchPage]) return;
        const { rows } = inboxChunks[searchPage];
        return rows.some((row, rowIndex) => {
            if (row.ID && row.ID.toString() === ID.toString()) {
                result.chunkIndex = searchPage;
                result.rowIndex = rowIndex;
                result.review = row;
            }
            return row.ID === ID;
        });
    });
    return result;
};

const InboxStore = {
    namespaced: true,
    state: emptyStatePopulated,
    mutations: {
        createInboxChunk(state, { page }) {
            Vue.set(state.inboxChunks, page, {
                page,
                loading: true,
                rows: [],
            });
        },
        setInboxChunk(state, { rows, paging, page }) {
            const mappedRows = rows.map((inboxItem) => {
                inboxItem.ID =
                    inboxItem.merchantReviewID ||
                    inboxItem.productReviewID ||
                    inboxItem.productReviewMeningID ||
                    inboxItem.productQuestionID;
                inboxItem.onPage = page;
                inboxItem.isRead = inboxItem.isRead === true;
                inboxItem.isPublished = inboxItem.isPublished === true;
                inboxItem.isReplied = inboxItem.isReplied === true;
                inboxItem.isRevised = inboxItem.isRevised === true;
                inboxItem.isAnswered = inboxItem.isAnswered === true;
                inboxItem.hasUploadedImage = inboxItem.hasUploadedImage === true;
                inboxItem.isSelected = !!state.selectedItems.find((el) => el === inboxItem.ID);

                let component;
                switch (inboxItem.type) {
                    case "product":
                        component = InboxItemProductReview;
                        break;
                    case "question":
                        component = InboxItemProductQuestion;
                        break;
                    default:
                        component = InboxItemMerchantReview;
                        break;
                }

                return { ...inboxItem, component };
            });
            Vue.set(state.inboxChunks, paging.page, {
                rows: mappedRows,
                total: paging.total,
                page: paging.page,
                loading: false,
            });
        },
        setSorting(state, { sorting }) {
            const routerSorting = sanitizeSorting({ sorting });
            const filteredSorting = removeObjectEmptyValues(sorting);
            router
                .push({
                    name: "Inbox",
                    query: routerSorting,
                    params: {
                        page: 1,
                    },
                })
                .catch(() => {});
            Vue.set(state, "sorting", filteredSorting);
        },
        clearSorting(state, { sorting }) {
            const filteredSorting = removeObjectEmptyValues(sorting);
            Vue.set(state, "sorting", filteredSorting);
        },
        setPaging(state, { paging }) {
            state.paging = paging;
        },
        setResponseInputCollapsed(state, { isCollapsed }) {
            state.responseInputCollapsed = isCollapsed;
        },
        setFilterTrayOpenPercentage(state, { openPercentage }) {
            if (openPercentage !== undefined && this.state.trayDragAllowed) {
                state.filterTrayOpenPercentage = openPercentage;
            }
        },
        setLoading(state, { loading }) {
            state.loading = loading;
        },
        clearData(state) {
            state.initialLoadDone = false;
            state.inboxChunksDisplayed = [];
            state.inboxChunks = {};
            state.paging = {};
            state.loading = true;
            state.reviewContext = { divisionId: null, divisionUuid: null, inboxPath: null };
            state.selectState = false;
            state.areAllSelected = false;
            state.numerOfSelectedItems = 0;
            state.numerOfSelectedUnreadItems = 0;
            state.selectedItems = [];
        },
        addDisplayChunk(state, { chunkPage }) {
            if (state.inboxChunksDisplayed.indexOf(chunkPage) > -1) return;
            state.inboxChunksDisplayed.push(chunkPage);
        },
        setInitialLoadDone(state, { initialLoadDone }) {
            state.initialLoadDone = initialLoadDone;
        },
        setReviewContext(state, { divisionId, divisionUuid, inboxPath }) {
            state.reviewContext = { divisionId, divisionUuid, inboxPath };
        },
        setSelectState(state) {
            state.selectState = !state.selectState;
            if (!state.selectState) {
                state.selectedItems = [];
            }
        },
        selectOrUnselectAllReviews(state, { selection }) {
            Object.values(state.inboxChunks).forEach((chunk) => {
                chunk.rows.forEach((row) => {
                    row.isSelected = selection;
                });
            });
            state.areAllSelected = selection;
            state.numerOfSelectedItems = selection ? state.paging.total : 0;
            state.numerOfSelectedUnreadItems = 0;
        },
        selectOrUnselectReview(state, { selection, id }) {
            const { inboxChunks } = state;
            const searchPages = [...Array(state.paging.pageNum + 1).keys()];
            const result = findInInboxChunks({ ID: id, inboxChunks, searchPages });
            result.review.isSelected = selection;
            if (selection) {
                state.numerOfSelectedItems += 1;
                state.selectedItems.push(result.review.ID);
                if (!result.review.isRead) {
                    state.numerOfSelectedUnreadItems += 1;
                }
            } else {
                state.numerOfSelectedItems -= 1;
                state.selectedItems = state.selectedItems.filter((el) => el !== result.review.ID);
                if (!result.review.isRead) {
                    state.numerOfSelectedUnreadItems -= 1;
                }
            }
        },
    },
    actions: {
        reloadAllInboxChunks({ state, dispatch }) {
            Object.values(state.inboxChunks).forEach((chunk) => {
                dispatch("getInboxChunk", { page: chunk.page });
            });
        },
        getInboxChunk({ state, commit }, { page }) {
            if (!state.inboxChunks[page]) {
                commit("createInboxChunk", { page });
            }
            const sortingHash = JSON.stringify(state.sorting);
            httpGet({
                url: `${process.env.VUE_APP_API_BASE_URL}/api/v3/reviewsCombined/list`,
                urlParams: {
                    rowsperpage: config.rowsPerPage,
                    page,
                    ...state.sorting,
                },
            })
                .then(({ data }) => {
                    if (sortingHash !== JSON.stringify(state.sorting)) return;
                    const { rows, paging } = data;
                    commit("setInboxChunk", { rows, paging, page });
                    if (!state.paging.total) commit("setPaging", { paging });
                    if (state.areAllSelected)
                        commit("selectOrUnselectAllReviews", { selection: true });
                })
                .finally(() => {
                    // Stop Loading
                    commit("setInitialLoadDone", { initialLoadDone: true });
                    commit("setLoading", { loading: false });
                });
        },
        onAccountSwitch({ commit }) {
            commit("clearData");
            window.requestAnimationFrame(() => {
                commit("addDisplayChunk", { chunkPage: 1 });
            });
        },
        setSortingParam({ state, commit }, { key, value }) {
            // Wait for a small amount of time before searching
            clearTimeout(window.sortingParamSetCountdown);
            window.sortingParamSetCountdown = window.setTimeout(() => {
                const sorting = state.sorting || {};
                sorting[key] = value;
                commit("setSorting", { sorting });
            }, 350);
        },
        clearFilters({ commit }) {
            commit("setSorting", {
                sorting: getEmptyState().sorting,
            });
        },
        clearSorting({ commit }) {
            commit("clearSorting", {
                sorting: getEmptyState().sorting,
            });
        },
        async getDivisionByReviewId({ commit }, { apolloClient, reviewId, reviewType, divisions }) {
            try {
                const result = await apolloClient.query({
                    query: getDivisionByReviewId,
                    variables: {
                        reviewId,
                        reviewType: reviewType.toUpperCase(),
                    },
                });
                const { divisionId, inboxPath } = result.data.initialUserData.reviewInfo;
                const divisionUuid = divisions.find(
                    (division) => division.externalId == divisionId
                )?.externalUuid;
                commit("setReviewContext", { divisionId, divisionUuid, inboxPath });
            } catch (errors) {
                Sentry.captureException(errors);
            }
        },
    },
    getters: {
        sortingFiltered: (state) => removeObjectEmptyValues(state.sorting),
        getAmountOfFiltersActive: (state) =>
            Object.keys(sanitizeSorting({ sorting: state.sorting })).length,
        getPreviousAndNextReview: (state) => {
            const { ID } = router.app._route.params;
            if (!ID) return false;
            const { chunkIndex } = router.app._route.query;
            const { inboxChunks } = state;
            const page = parseInt(chunkIndex, 10);
            const searchPages = [page - 1, page, page + 1];
            let loaded = false;
            searchPages.forEach((searchPage) => {
                if (inboxChunks[searchPage] !== undefined && !inboxChunks[searchPage].loading) {
                    loaded = true;
                }
            });
            if (loaded === false) return false;
            const result = findInInboxChunks({ ID, inboxChunks, searchPages });
            if (result.chunkIndex === null || result.rowIndex === null) return false;
            // If review is in next chunk, get first review of next chunk
            const nextIndex = { chunk: result.chunkIndex, row: result.rowIndex + 1 };
            const previousIndex = { chunk: result.chunkIndex, row: result.rowIndex - 1 };
            if (nextIndex.row >= config.rowsPerPage) {
                nextIndex.chunk += 1;
                nextIndex.row = 0;
            }
            if (previousIndex.row < 0) {
                previousIndex.chunk -= 1;
                previousIndex.row = config.rowsPerPage - 1;
            }
            let next = { ID: null, page: null };
            if (inboxChunks[nextIndex.chunk] && inboxChunks[nextIndex.chunk].rows[nextIndex.row]) {
                next = {
                    ID: inboxChunks[nextIndex.chunk].rows[nextIndex.row].ID,
                    page: inboxChunks[nextIndex.chunk].rows[nextIndex.row].onPage,
                    type: inboxChunks[nextIndex.chunk].rows[nextIndex.row].type,
                };
            }
            let previous = { ID: null, page: null };
            if (
                inboxChunks[previousIndex.chunk] &&
                inboxChunks[previousIndex.chunk].rows[previousIndex.row]
            ) {
                previous = {
                    ID: inboxChunks[previousIndex.chunk].rows[previousIndex.row].ID,
                    page: inboxChunks[previousIndex.chunk].rows[previousIndex.row].onPage,
                    type: inboxChunks[previousIndex.chunk].rows[previousIndex.row].type,
                };
            }
            return { next, previous };
        },
        getActiveFilters: (state) => {
            const filtersActive = { ...state.sorting };
            Object.keys(filtersActive).forEach((key) => {
                if (filtersActive[key] === getEmptyState().sorting[key] || key === "chunkIndex") {
                    delete filtersActive[key];
                }
            });
            return Object.keys(filtersActive);
        },
        getNumberOfSelectedItems: (state) => {
            let numberOfSelectedItems = 0;
            Object.values(state.inboxChunks).forEach((chunk) => {
                numberOfSelectedItems += chunk.rows.filter((row) => row.isSelected).length;
            });
            return numberOfSelectedItems;
        },
        getIdsOfSelectedItems: (state) => (type) => {
            const ids = [];
            Object.values(state.inboxChunks).forEach((chunk) => {
                chunk.rows.forEach((row) => {
                    if (row.isSelected && row.type === type) ids.push(row.ID);
                });
            });
            return ids;
        },
    },
};

export default InboxStore;
