import {
    getActions,
    getGetters,
    getMutations,
} from "@/stores/store_extensions/StepperStoreExtension";

const SELECT_QUESTIONNAIRE = "SELECT_QUESTIONNAIRE";
const CREATE_RESPONDENTS_LIST = "CREATE_RESPONDENTS_LIST";
const SCHEDULE_DATES = "SCHEDULE_DATES";
const CONFIRM_INVITATIONS = "CONFIRM_INVITATIONS";
const INVITATIONS_SCHEDULED = "INVITATIONS_SCHEDULED";

const stepSelectQuestionnaire = {
    id: SELECT_QUESTIONNAIRE,
    label: "cp__research_invitations__step__select_questionaire",
    icon: ["fal", "clipboard-list"],
    nextStepPredicate: () => true,
    movingToQuestionnaireRequiresConfirmation: () => false,
    completed: false,
    data: null,
};
const stepCreateRespondentsList = {
    id: CREATE_RESPONDENTS_LIST,
    label: "cp__research_invitations__step__create_respondents_list",
    icon: ["fal", "list"],
    nextStepPredicate: () => true,
    movingToQuestionnaireRequiresConfirmation: () => false,
    completed: false,
    data: null,
};
const stepScheduleDates = {
    id: SCHEDULE_DATES,
    label: "cp__research_invitations__step__schedule_dates",
    icon: ["fal", "calendar-day"],
    nextStepPredicate: () => true,
    movingToQuestionnaireRequiresConfirmation: () => false,
    completed: false,
    data: null,
};
const stepConfirmInvitations = {
    id: CONFIRM_INVITATIONS,
    label: "cp__research_invitations__step__confirm_invitations",
    icon: ["fal", "clipboard-list-check"],
    nextStepPredicate: () => true,
    movingToQuestionnaireRequiresConfirmation: () => false,
    completed: false,
    data: null,
};
const stepInvitationsScheduled = {
    id: INVITATIONS_SCHEDULED,
    label: "cp__research_invitations__step__invitations_scheduled",
    icon: ["fas", "check-circle"],
    nextStepPredicate: () => true,
    movingToQuestionnaireRequiresConfirmation: () => false,
    completed: false,
    data: null,
};

export default {
    namespaced: true,
    state: {
        stepIds: {
            SELECT_QUESTIONNAIRE,
            CREATE_RESPONDENTS_LIST,
            SCHEDULE_DATES,
            CONFIRM_INVITATIONS,
            INVITATIONS_SCHEDULED,
        },
        /**
         * Steps are an object that need to adhere to the following format:
         *
         * const step: {
         *      id - Unique constant that functions as the property name within the steps object literal.
         *      label - i18n label.
         *      icon - Fontawesome icon in array notation.
         *      nextStepPredicate - Closure that needs to returns true or false.
         *          Used for step content to communicate whether all requirements have been
         *          met to move on to the next step
         *      movingToQuestionnaireRequiresConfirmation - when this flag is true, the user must be presented
         *          with a confirmation dialogue to before the steps' data is reset and moves back.
         *      completed - flag to indicate the completion of this step.
         *      data - Step specific state such as form data.
         * }
         */
        steps: {
            SELECT_QUESTIONNAIRE: stepSelectQuestionnaire,
            CREATE_RESPONDENTS_LIST: stepCreateRespondentsList,
            SCHEDULE_DATES: stepScheduleDates,
            CONFIRM_INVITATIONS: stepConfirmInvitations,
            INVITATIONS_SCHEDULED: stepInvitationsScheduled,
        },
        stepSequence: [
            stepSelectQuestionnaire,
            stepCreateRespondentsList,
            stepScheduleDates,
            stepConfirmInvitations,
            stepInvitationsScheduled,
        ],
        activeStep: SELECT_QUESTIONNAIRE,
        loading: false,
    },
    mutations: {
        ...getMutations(),
        setMovingToQuestionnaireRequiresConfirmation(state, { stepId, predicate }) {
            state.steps[stepId].movingToQuestionnaireRequiresConfirmation = predicate;
        },
    },
    actions: {
        ...getActions(),
    },
    getters: {
        ...getGetters(),
        moveToQuestionnaireRequiresConfirmation: (state) => (targetStep) =>
            targetStep === SELECT_QUESTIONNAIRE
                ? state.steps[state.activeStep].movingToQuestionnaireRequiresConfirmation()
                : false,
    },
};
