const popups = {
    namespaced: true,
    state() {
        return {
            open: [],
        };
    },
    mutations: {
        open(state, { ID }) {
            if (state.open.find((popupID) => popupID === ID)) return;
            state.open.push(ID);
        },
        close(state, { ID }) {
            state.open = state.open.filter((popupID) => popupID !== ID);
        },
    },
    getters: {
        openCount: (state) => state.open.length,
    },
};

export default popups;
