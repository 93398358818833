import { sortBy } from "lodash";
import * as Sentry from "@sentry/vue";
import httpGet from "@/helpers/httpGet";
import getInitialUserData from "@/graphql/getInitialUserData.gql";
import switchDivision from "@/graphql/switchDivision.gql";
import getDashboardsList from "@/graphql/dashboards/getDashboardsList.gql";
import { resolveGraphQLErrors } from "@/helpers/resolveGraphQLErrors";

const emptyDivision = {
    id: null,
    name: null,
    slug: null,
    uuid: null,
    logo: null,
};

const AccountStore = {
    namespaced: true,
    state: {
        keycloak: null,
        logoutTrayOpenPercentage: 0,
        divisionSwitchTrayOpenPercentage: 0,
        subNavigationTrayOpenPercentage: 0,
        shopData: {},
        divisions: [],
        permissions: [],
        user: null,
        forcedSwitchPopupOpen: false,
        errors: {
            accountSwitch: {},
            getUser: {},
        },
        loading: {
            accountSwitching: "",
            getUser: false,
        },
        successfulRequests: {
            getUser: false,
        },
        treeData: [],
        dashboards: [], // for ev users to nav to using dynamic urls
    },
    mutations: {
        logoutTrayOpenPercentage(state, { openPercentage }) {
            if (openPercentage !== undefined && this.state.trayDragAllowed) {
                state.logoutTrayOpenPercentage = openPercentage;
            }
        },
        divisionSwitchTrayOpenPercentage(state, { openPercentage }) {
            if (openPercentage !== undefined && this.state.trayDragAllowed) {
                state.divisionSwitchTrayOpenPercentage = openPercentage;
            }
        },
        subNavigationTrayOpenPercentage(state, { openPercentage }) {
            if (openPercentage !== undefined && this.state.trayDragAllowed) {
                state.subNavigationTrayOpenPercentage = openPercentage;
            }
        },
        addErrors(state, { view, errors }) {
            state.errors[view] = errors;
        },
        addSwitchAccountErrors(state, { uuid, errors }) {
            state.errors.accountSwitch[uuid] = errors;
        },
        clearErrors(state, { view }) {
            state.errors[view] = [];
        },
        setLoading(state, { view, loading }) {
            state.loading[view] = loading;
        },
        setSuccessfulRequests(state, { view, success }) {
            state.successfulRequests[view] = success;
        },
        setShopData(state, { shopData }) {
            const { activeDivision, divisions, permissions, user } = shopData;
            state.user = user;
            localStorage.setItem("activeShop", JSON.stringify(activeDivision));
            localStorage.setItem("accountUuid", activeDivision?.externalUuid);
            localStorage.setItem("platform", user.platform);
            state.shopData = activeDivision || emptyDivision;
            state.divisions = sortBy(divisions, ["name"]);
            state.permissions = permissions;
            const children = [];
            if (state.divisions?.length > 1) {
                state.divisions.forEach((division) => {
                    children.push({
                        id: division.externalId,
                        uuid: division.externalUuid,
                        name: division.name,
                        isActive: division.externalId === activeDivision.externalId,
                    });
                });
                state.treeData = [
                    {
                        name: state.user.company.name,
                        isOpen: true,
                        children,
                    },
                ];
            }

            if (shopData.dashboards) {
                state.dashboards = shopData.dashboards;
            }
        },
        setForcedSwitchPopup(state, { open }) {
            state.forcedSwitchPopupOpen = open;
        },
        updateAccountDataByKey(state, updatedFields) {
            updatedFields.forEach((field) => {
                state.shopData[field.key] = field.value;
            });
        },
        setDashboards(state, { dashboards }) {
            state.dashboards = dashboards;
        },
    },
    getters: {
        activeAccount(state) {
            return state.shopData;
        },
        accountName(state) {
            return state.shopData.name ? state.shopData.name : state.user.platform;
        },
        accessibleAccounts(state) {
            if (!state.divisions) return;
            return state.divisions.filter((account) => account.id !== state.shopData.externalId``);
        },
        /**
         * Evaluates the presence of a set of permissions
         * @param state
         * @returns {(function(*))|*}
         */
        hasPermissions: (state) => (permissions) => {
            const hasPermissions =
                permissions.filter((permission) => state.permissions.includes(permission)).length >
                0;
            return hasPermissions;
        },
        treeData(state) {
            return state.treeData;
        },
        activeUser(state) {
            return state.user;
        },
    },
    actions: {
        logout({ dispatch, state }, { redirectToLogin }) {
            localStorage.removeItem("accessToken");

            if (redirectToLogin !== false) {
                dispatch("onAccountSwitch", { logout: true }, { root: true });
                state.keycloak.logout();
            }
        },
        async getCurrentUser({ commit, state, dispatch }, { apolloClient }) {
            commit("clearErrors", { view: "getUser" });
            commit("setLoading", { view: "getUser", loading: true });
            try {
                const { data } = await apolloClient.query({ query: getInitialUserData });
                commit("setShopData", { shopData: data.initialUserData });
            } catch (errors) {
                Sentry.captureException(errors);
                const graphQLErrors = resolveGraphQLErrors(errors);
                commit("setSuccessfulRequests", { view: "getUser", success: false });
                commit("addErrors", {
                    view: "getUser",
                    errors: graphQLErrors || ["UNKNOWN"],
                });
                if (state.errors.getUser.includes("UNAUTHORIZED")) {
                    dispatch("logout", { redirectToLogin: true });
                }
            }
        },
        async switchAccount(
            { commit, dispatch, state },
            { uuid, executeOnAccountSwitch, apolloClient }
        ) {
            try {
                commit("clearErrors", { view: "accountSwitch" });
                if (executeOnAccountSwitch !== false) {
                    commit("setLoading", { view: "accountSwitching", loading: uuid });
                }
                const { data } = await apolloClient.mutate({
                    mutation: switchDivision,
                    variables: {
                        divisionUuid: uuid,
                    },
                    context: {
                        headers: {
                            "X-Shop-Uuid": state.shopData.externalUuid,
                        },
                    },
                });
                localStorage.setItem(
                    "accountUuid",
                    data.switchDivision.activeDivision.externalUuid
                );
                // todo: only call reLogin if user "isAppreciation"
                await dispatch("reLoginToOldPortal");
                commit("setShopData", { shopData: data.switchDivision });
                if (executeOnAccountSwitch !== false) {
                    dispatch("onAccountSwitch", {}, { root: true });
                    commit("setForcedSwitchPopup", { open: false });
                } else {
                    window.requestAnimationFrame(() => {
                        commit("setForcedSwitchPopup", { open: false });
                    });
                }
            } catch (errors) {
                Sentry.captureException(errors);
                const graphQLErrors = resolveGraphQLErrors(errors);
                commit("addSwitchAccountErrors", {
                    uuid,
                    graphQLErrors,
                });
            } finally {
                if (executeOnAccountSwitch !== false) {
                    commit("setLoading", { view: "accountSwitching", loading: "" });
                }
            }
        },
        async reLoginToOldPortal() {
            await httpGet({
                url: `${process.env.VUE_APP_API_BASE_URL}/api/v3/User/reLoginToOldPortal`,
            }).catch((errors) => {
                Sentry.captureException(errors);
                /* eslint-disable no-console */
                console.log(errors);
            });
        },
        refreshAuthToken({ state, dispatch }) {
            state.keycloak
                .updateToken(35)
                .then((tokenHasBeenRefreshed) => {
                    if (!tokenHasBeenRefreshed) return;
                    localStorage.setItem("accessToken", state.keycloak.token);
                    if (state.user.isAppreciation) dispatch("reLoginToOldPortal");
                })
                .catch(() => {
                    state.keycloak.logout();
                });
        },
        async getDashboards({ commit }, { apolloClient }) {
            try {
                const { data } = await apolloClient.query({ query: getDashboardsList });
                commit("setDashboards", { dashboards: data.dashboardViews });
            } catch (e) {
                Sentry.captureException(e);
                /* eslint-disable no-console */
                console.error(e);
            }
        },
    },
};

export default AccountStore;
