import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from "vue";
import setup from "@feedbackcompany/feedback-company-vue-components/src/setup";
import lineClamp from "vue-line-clamp";
import Vue2TouchEvents from "vue2-touch-events";
// eslint-disable-next-line import/no-extraneous-dependencies
import Element from "element-ui";
import DataTables from "vue-data-tables";
import InfiniteLoading from "vue-infinite-loading";
import vClickOutside from "v-click-outside";
import VueGtag from "vue-gtag";
import TextareaAutosize from "vue-textarea-autosize";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import PortalVue from "portal-vue";
import VueTippy from "vue-tippy";
import Progress from "vue-multiple-progress";
import { useKeycloak } from "@/helpers/useKeycloak";
import storage from "@/helpers/evaluation/storage";
import i18n from "@/i18n";
import { getGraphQLServerError } from "@/helpers/resolveGraphQLErrors";
import useDashboardExportMode from "@/helpers/evaluation/useDashboardExportMode";
import DashboardExport from "@/components/Dashboards/DashboardExport.vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createProvider } from "./vue-apollo";
import { version } from "../package.json";

const dashboardExportMode = useDashboardExportMode();

async function asyncStarter(keycloak, dashboardExportModeEnabled) {
    Vue.config.productionTip = false;

    Vue.use(PortalVue);
    Vue.use(Vue2TouchEvents);
    Vue.use(TextareaAutosize);
    Vue.use(lineClamp);

    if (process.env.VUE_APP_ENVIRONMENT !== "development") {
        Sentry.init({
            Vue,
            dsn: process.env.VUE_APP_SENTRY_DSN,
            release: `feedback-company-portal@${version}`,
            environment: process.env.VUE_APP_ENVIRONMENT,
            integrations: [
                new BrowserTracing({
                    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                    tracingOrigins: [
                        "local-portal.feedbackcompany.com",
                        "test-portal.feedbackcompany.com",
                        "staging-portal.feedbackcompany.com",
                        "portal.feedbackcompany.com",
                        /^\//,
                    ],
                }),
            ],
            tracesSampleRate: process.env.VUE_APP_ENVIRONMENT === "production" ? 0.5 : 1,
            tracingOptions: {
                trackComponents: true,
            },
            maxBreadcrumbs: 50,
            debug: process.env.VUE_APP_ENVIRONMENT !== "production",
            logErrors: process.env.VUE_APP_ENVIRONMENT !== "production",
            attachProps: true,
            attachStacktrace: true,
        });
    }

    Vue.config.productionTip = false;
    Vue.use(Progress);

    // Evaluation plugin config
    Vue.use(DataTables);
    Vue.use(InfiniteLoading, {
        slots: {
            noMore: { render: (h) => h("span") },
            noResults: { render: (h) => h("span") },
        },
    });
    Vue.use(vClickOutside);
    Vue.use(Element, {
        i18n: (key, value) => i18n.t(key, value),
    });
    Vue.use(storage);

    Vue.use(VueTippy, {
        placement: "bottom",
        arrow: true,
        followCursor: true,
        distance: 25,
        animation: "fade",
        delay: [500, 0],
    });

    Vue.use(
        VueGtag,
        {
            appName: "Customer Portal",
            config: { id: "UA-18789410-19" },
        },
        router
    );

    Vue.config.errorHandler = (error, vm, info) => {
        if (
            info.includes("mounted") ||
            info.includes("created") ||
            info.includes("beforeCreate") ||
            info.includes("beforeMount")
        ) {
            const serverError = getGraphQLServerError(error);
            if (serverError) {
                router.push({
                    name: serverError.code,
                    params: { error: `${serverError.code} ${serverError.message}` },
                });
            }
        }
    };

    setup();

    store.state.AccountStore.keycloak = keycloak;
    Vue.prototype.store = store;

    if (dashboardExportModeEnabled) {
        new Vue({
            store,
            i18n,
            apolloProvider: createProvider({ vuexStore: store }),
            render: (h) => h(DashboardExport),
        }).$mount("#app");
    } else {
        new Vue({
            router,
            store,
            i18n,
            apolloProvider: createProvider({ vuexStore: store, router }),
            render: (h) => h(App),
        }).$mount("#app");
    }
}

if (dashboardExportMode.enabled) {
    asyncStarter(null, true);
} else {
    useKeycloak().then((keycloak) => asyncStarter(keycloak));
}
