<template>
    <InboxItemBase v-bind="$props">
        <template v-slot:titleStrong>
            <span :title="productName">{{ productName }}</span>
        </template>
        <template v-slot:icons>
            <font-awesome-icon
                :title="$t('cp__inbox__status__approved')"
                v-if="published"
                class="inbox_item__published_status_icon inbox_item__published_status_icon--published"
                :icon="['fas', 'check-circle']"
            />
            <font-awesome-icon
                :title="$t('cp__inbox__card_product_review_tag')"
                class="inbox_item__review_type_icon"
                :icon="['far', 'shopping-bag']"
            />
            <font-awesome-icon
                v-if="hasUploadedImage"
                :title="$t('cp__inbox__filter__has__uploaded_image')"
                class="inbox_item__image_icon"
                :icon="['far', 'image']"
            />
            <font-awesome-icon
                v-if="replied"
                :title="$t('cp__inbox__status__replied')"
                class="inbox_item__replied_status_icon"
                :icon="['fas', 'reply']"
            />
            <Stars :size="16" :rating="rating" />
        </template>
    </InboxItemBase>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faShoppingBag, faImage } from "@fortawesome/pro-regular-svg-icons";
import { faClock, faReply } from "@fortawesome/pro-solid-svg-icons";
import Stars from "@/components/Inbox/Atoms/Stars.vue";
import { baseProps, default as InboxItemBase } from "./InboxItemBase.vue";

library.add(faClock, faShoppingBag, faReply, faImage);

export default {
    name: "InboxItemProductReview",
    components: {
        InboxItemBase,
        Stars,
        FontAwesomeIcon,
    },
    props: {
        ...baseProps,
        productName: {
            required: true,
        },
        rating: {
            type: Number,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.inbox_item__published_status_icon,
.inbox_item__review_type_icon,
.inbox_item__replied_status_icon {
    color: $grey_mid;
    font-size: 1rem;
    margin-right: 12px;
}
.inbox_item__published_status_icon--published {
    color: $green;
}
.inbox_item__image_icon {
    color: $grey_mid;
    margin-right: 12px;
}
</style>
