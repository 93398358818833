<template>
    <router-link
        v-if="featureUnlocked"
        tag="li"
        class="notification_button"
        :to="to"
        :class="`notification_button--${this.colorScheme}`"
        @click.native="closeNotificationViewer"
    >
        <div class="notification_button__thumbnail">
            <slot></slot>
        </div>
        <span class="notification_button__text_content">
            <strong :title="`${count} ${label}`" class="notification_button__count">
                {{ formattedCount }}
            </strong>
            {{ computedLabel }}
        </span>
    </router-link>
    <a
        v-else
        href="https://feedbackcompany.com/product-reviews"
        class="notification_button notification_button--grey"
        target="_blank"
    >
        <div class="notification_button__thumbnail">
            <font-awesome-icon :icon="['fal', 'unlock']" />
        </div>
        <span class="notification_button__text_content"> Unlock Product Reviews </span>
    </a>
</template>

<script>
import { mapMutations } from "vuex";
import numeral from "numeral";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUnlock } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import animateXtoY from "@feedbackcompany/feedback-company-vue-components/src/helpers/animationHelper";

library.add(faUnlock);

export default {
    name: "NotificationButton",
    components: { FontAwesomeIcon },
    props: {
        count: {
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        to: {
            type: String,
            required: true,
        },
        colorScheme: {
            type: String,
            default: "blue",
            validator(value) {
                return ["blue", "green", "cyan"].indexOf(value) !== -1;
            },
        },
    },
    computed: {
        formattedCount() {
            return numeral(this.count).format("0a");
        },
        featureUnlocked() {
            return this.count !== null;
        },
        computedLabel() {
            let { label } = this;
            if (!this.featureUnlocked) label = "Unlock this feature";
            return label;
        },
    },
    methods: {
        ...mapMutations({
            setNotificationTrayOpenPercentage: "NotificationStore/notificationTrayOpenPercentage",
        }),
        closeNotificationViewer() {
            // When notification is selected, both the mobile menu and the nav tray should collapse
            // that is what this function facilitates
            if (window.innerWidth < 1001) {
                animateXtoY({
                    x: 1,
                    y: 0,
                    time: 300,
                    easeName: "easeOutQuad",
                    executable: ({ setTo }) => {
                        this.setNotificationTrayOpenPercentage({
                            openPercentage: setTo,
                        });
                        this.$store.commit("navigation", {
                            openPercentage: setTo,
                        });
                    },
                });
            } else {
                this.setNotificationTrayOpenPercentage({
                    openPercentage: 0,
                });
                this.$store.commit("navigation", {
                    openPercentage: 0,
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.notification_button {
    @extend %body2_style;
    display: flex;
    align-items: center;
    height: 60px;
    list-style: none;
    padding: 0 12px;
    text-decoration: none;
    @media (max-width: 1000px) {
        font-size: 1em;
        height: 96px;
    }
    &:hover {
        cursor: pointer;
        background: darken($white, 2);
    }
}
.notification_button--blue {
    .notification_button__thumbnail {
        background: rgba($blue, 0.1);
        color: $blue;
    }
}
.notification_button--green {
    .notification_button__thumbnail {
        background: rgba($bright_turquoise, 0.1);
        color: $green;
    }
}
.notification_button--cyan {
    .notification_button__thumbnail {
        background: rgba($cyan, 0.1);
        color: $cyan;
    }
}
.notification_button--grey {
    .notification_button__thumbnail {
        background: $grey_alabaster;
    }
}
.notification_button__thumbnail {
    width: 36px;
    height: 36px;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    @media (max-width: 1000px) {
        width: 72px;
        height: 72px;
        border-radius: 8px;
    }
}
.notification_button__thumbnail_icon {
    font-size: 16px;
    @media (max-width: 1000px) {
        font-size: 24px;
    }
}
</style>
