import * as Sentry from "@sentry/vue";
import httpPut from "@/helpers/httpPut";
import TypeExtension from "./store_extensions/StoreTypeExtension";

const reviewBase = {
    loading: false,
    hasError: false,
};

const baseStore = TypeExtension({
    get: {
        url: `${process.env.VUE_APP_API_BASE_URL}/api/v3/review/getProductQuestionDetail`,
    },
    reply: {
        url: `${process.env.VUE_APP_API_BASE_URL}/api/v3/review/answerProductQuestion`,
    },
    setRead: {
        url: `${process.env.VUE_APP_API_BASE_URL}/api/v3/review/setProductQuestionIsRead`,
    },
});

const customActions = {
    setPublic({ commit, getters, dispatch }, { ID, setPublic }) {
        let review = {
            ...reviewBase,
            ...getters.getReviewByID(ID),
        };
        commit("setReview", { review });
        httpPut({
            url: `${process.env.VUE_APP_API_BASE_URL}/api/v3/review/publishProductQuestion`,
            urlParams: {
                id: ID,
                setPublic,
            },
        })
            .then(() => {
                review = {
                    ...reviewBase,
                    ...getters.getReviewByID(ID),
                    setPublic,
                };
                dispatch("get", { ID });
                dispatch("InboxStore/getInboxChunk", { page: review.chunkIndex }, { root: true });
            })
            .catch((error) => {
                Sentry.captureException(error);
                /* eslint-disable no-console */
                console.error(error);
            });
    },
};

baseStore.actions = {
    ...baseStore.actions,
    ...customActions,
};

export default {
    ...baseStore,
};
