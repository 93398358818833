import * as Sentry from "@sentry/vue";
import i18n from "@/i18n";
import httpPut from "@/helpers/httpPut";
import httpPost from "@/helpers/httpPost";
import TypeExtension from "./store_extensions/StoreTypeExtension";

const baseStore = TypeExtension({
    get: {
        url: `${process.env.VUE_APP_API_BASE_URL}/api/v3/review/get`,
    },
    reply: {
        url: `${process.env.VUE_APP_API_BASE_URL}/api/v3/review/merchantReviewReply`,
    },
    setRead: {
        url: `${process.env.VUE_APP_API_BASE_URL}/api/v3/review/updateMerchantReviewIsRead`,
    },
    originalReply: "",
});

export default {
    namespaced: {
        ...baseStore.namespaced,
    },
    modules: {
        ...baseStore.modules,
    },
    state: {
        ...baseStore.state,
    },
    mutations: {
        ...baseStore.mutations,
    },
    actions: {
        ...baseStore.actions,
        approve({ commit, getters, dispatch }, { ID }) {
            const review = {
                ...baseStore.reviewBase,
                ...getters.getReviewByID(ID),
                approveSubmitting: true,
            };
            commit("setReview", { review });
            httpPut({
                url: `${process.env.VUE_APP_API_BASE_URL}/api/v3/review/approve`,
                urlParams: {
                    reviewID: ID,
                },
            })
                .then(() => {
                    review.approveSubmitting = false;
                    review.publish_date = new Date();
                    review.status = "approved";
                    commit("setReview", { review });
                    dispatch(
                        "InboxStore/getInboxChunk",
                        { page: review.chunkIndex },
                        { root: true }
                    );
                    dispatch(
                        "pushNotification",
                        {
                            type: "success",
                            title: i18n.t(
                                "cp__notifications__inbox__approve__successful__title",
                                "Review Published"
                            ),
                            message: i18n.t(
                                "cp__notifications__inbox__approve__successful__message",
                                "The review has been approved and published."
                            ),
                        },
                        { root: true }
                    );
                })
                .catch((error) => {
                    Sentry.captureException(error);
                    commit("setReview", {
                        review: {
                            ...baseStore.reviewBase,
                            ID,
                            hasError: true,
                            error,
                        },
                    });
                })
                .finally(() => {
                    commit("setReview", {
                        review: {
                            ...getters.getReviewByID(ID),
                            approveSubmitting: false,
                        },
                    });
                });
        },
        requestRevise({ commit, getters, dispatch }, { ID, reviseText }) {
            return new Promise((resolve, reject) => {
                const review = {
                    ...baseStore.reviewBase,
                    ...getters.getReviewByID(ID),
                    requestReviseSubmitting: true,
                };
                commit("setReview", { review });
                httpPost({
                    url: `${process.env.VUE_APP_API_BASE_URL}/api/v3/review/reInvite`,
                    body: {
                        reviewID: ID,
                        mailBody: reviseText,
                    },
                })
                    .then(() => {
                        review.reinviteSent = true;
                        commit("setReview", { review });
                        dispatch(
                            "InboxStore/getInboxChunk",
                            { page: review.chunkIndex },
                            { root: true }
                        );
                        dispatch(
                            "pushNotification",
                            {
                                type: "success",
                                title: i18n.t(
                                    "cp__notifications__inbox__revise_request__successful__title",
                                    "Revision Requested"
                                ),
                                message: i18n.t(
                                    "cp__notifications__inbox__revise_request__successful__message",
                                    "Invitation to revise is sent to the reviewer."
                                ),
                            },
                            { root: true }
                        );
                        resolve();
                    })
                    .catch((error) => {
                        Sentry.captureException(error);
                        commit("setReview", {
                            review: {
                                ...baseStore.reviewBase,
                                ...getters.getReviewByID(ID),
                                ID,
                                requestReviseErrors: error,
                            },
                        });
                        reject();
                    })
                    .finally(() => {
                        // get the latest review so we can get the reviseDate
                        dispatch("get", { ID });
                    });
            });
        },
        updateReviewData({ commit, getters, dispatch }, { ID, product, filtercode, locationUUID }) {
            let review = {
                ...baseStore.reviewBase,
                ...getters.getReviewByID(ID),
            };
            const urlParams = {
                reviewID: ID,
            };
            if (product) urlParams.product = product;
            if (filtercode) urlParams.filtercode = filtercode;
            if (locationUUID) urlParams.locationUUID = locationUUID;
            commit("setReview", { review });
            httpPut({
                url: `${process.env.VUE_APP_API_BASE_URL}/api/v3/review/updateReviewData`,
                urlParams,
            })
                .then(() => {
                    review = {
                        ...baseStore.reviewBase,
                        ...getters.getReviewByID(ID),
                        ...urlParams,
                    };
                    commit("setReview", { review });
                    dispatch(
                        "InboxStore/getInboxChunk",
                        { page: review.chunkIndex },
                        { root: true }
                    );
                    dispatch("NotificationStore/getNotificationCount", {}, { root: true });
                })
                .catch((error) => {
                    Sentry.captureException(error);
                    /* eslint-disable no-console */
                    console.error(error);
                });
        },
    },
    getters: {
        ...baseStore.getters,
    },
};
