// Extension for Product Review, Company Review, Product Questions Types

import Vue from "vue";
import * as Sentry from "@sentry/vue";
import i18n from "@/i18n";
import PopupStoreExtension from "@/stores/store_extensions/PopupStoreExtension";
import router from "@/router";
import httpGet from "@/helpers/httpGet";
import httpPut from "@/helpers/httpPut";
import httpPost from "@/helpers/httpPost";

const reviewBase = {
    loading: false,
    hasError: false,
};

// eslint-disable-next-line func-names
export default function (specifics) {
    return {
        reviewBase,
        namespaced: true,
        modules: {
            Popups: PopupStoreExtension,
        },
        state: {
            reviews: {},
        },
        mutations: {
            setReview(state, { review }) {
                Vue.set(state.reviews, review.ID, review);
            },
            clearData(state) {
                state.reviews = {};
            },
        },
        actions: {
            onAccountSwitch({ commit }) {
                commit("clearData");
            },
            get({ commit, getters }, { ID }) {
                const { chunkIndex } = router.app._route.query;
                if (!getters.getReviewByID(ID)) {
                    commit("setReview", {
                        review: {
                            ...reviewBase,
                            ID,
                            chunkIndex,
                            loading: true,
                        },
                    });
                }
                httpGet({
                    url: specifics.get.url,
                    urlParams: {
                        reviewID: ID,
                    },
                })
                    .then(({ data }) => {
                        commit("setReview", {
                            review: {
                                ...reviewBase,
                                ID,
                                chunkIndex,
                                ...data,
                            },
                        });
                    })
                    .catch((error) => {
                        Sentry.captureException(error);
                        commit("setReview", {
                            review: {
                                ...reviewBase,
                                ID,
                                chunkIndex,
                                hasError: true,
                                error,
                            },
                        });
                    });
            },
            reply({ commit, getters, dispatch }, { ID, reply, setPublic, successMessage }) {
                if (!successMessage) {
                    successMessage = {
                        title: i18n.t("cp__notifications__inbox__reply__successful__title"),
                        message: i18n.t("cp__notifications__inbox__reply__successful__message"),
                    };
                }
                const review = {
                    ...reviewBase,
                    ...getters.getReviewByID(ID),
                    replySubmitting: true,
                };
                const editingReply =
                    (review.shop_comment_date || review.shop_comment !== "") &&
                    review.answer === undefined;
                commit("setReview", { review });
                const body = {
                    reviewID: ID,
                    replyMsg: reply,
                };
                if (setPublic !== undefined) body.setPublic = setPublic;
                httpPost({
                    url: specifics.reply.url,
                    body,
                })
                    .then(() => {
                        dispatch("get", { ID });
                        let pushMessage = successMessage;
                        if (editingReply) {
                            pushMessage = {
                                title: i18n.t(
                                    "cp__notifications__inbox__reply_edited__successful__title"
                                ),
                                message: i18n.t(
                                    "cp__notifications__inbox__reply_edited__successful__message"
                                ),
                            };
                        }
                        dispatch(
                            "pushNotification",
                            { type: "success", ...pushMessage },
                            { root: true }
                        );
                    })
                    .catch((error) => {
                        Sentry.captureException(error);
                        /* eslint-disable no-console */
                        console.warn(error);
                        commit("setReview", {
                            review: {
                                ...reviewBase,
                                ID,
                                hasError: true,
                                error,
                            },
                        });
                    })
                    .finally(() => {
                        commit("setReview", {
                            review: {
                                ...getters.getReviewByID(ID),
                                replySubmitting: false,
                            },
                        });
                    });
            },
            async setRead({ commit, getters, dispatch }, { ID, isRead }) {
                let review = {
                    ...reviewBase,
                    ...getters.getReviewByID(ID),
                };
                commit("setReview", { review });
                await httpPut({
                    url: specifics.setRead.url,
                    urlParams: {
                        reviewID: ID,
                        isRead,
                    },
                })
                    .then(() => {
                        review = {
                            ...reviewBase,
                            ...getters.getReviewByID(ID),
                            isRead,
                        };
                        commit("setReview", { review });
                        dispatch(
                            "InboxStore/getInboxChunk",
                            { page: review.chunkIndex },
                            { root: true }
                        );
                        dispatch("NotificationStore/getNotificationCount", {}, { root: true });
                    })
                    .catch((error) => {
                        Sentry.captureException(error);
                        /* eslint-disable no-console */
                        console.error(error);
                    });
            },
        },
        getters: {
            getReviewByID: (state) => (ID) => state.reviews[ID],
        },
    };
}
