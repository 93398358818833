<template>
    <div>
        <DesktopMenubar />
        <main class="main">
            <img class="image" src="../../assets/error/coming-soon.svg" alt="Coming Soon" />
            <div class="text">
                <h1 class="title">{{ $t("cp__error_page__coming_soon__title") }}</h1>
                <p class="subtitle" v-html="$t('cp__error_page__coming_soon__subtitle')"></p>
            </div>
        </main>
    </div>
</template>

<script>
import DesktopMenubar from "@/components/Layout/DesktopMenubar.vue";

export default {
    name: "ParentEntityMissingError",
    components: { DesktopMenubar },
};
</script>

<style lang="scss" scoped>
@import "../../style_variables/style_variables.scss";
@import "~include-media";

.main {
    @extend %body1_style;
    background-color: $grey_bg;
    height: calc(100vh - 56px);
    width: 100%;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .image {
        max-width: 100%;
        width: 500px;
        @include media($isPhone) {
            width: 300px;
        }
    }

    .text {
        margin-top: 56px;
        text-align: center;
        max-width: 500px;

        .title {
            @extend %headline5_style;
            margin-top: 0;
            font-weight: 700;
            @include media($isPhone) {
                font-size: 1rem;
            }
        }

        .subtitle {
            @extend %subtitle1_style;
            text-align: center;
            margin: 12px 0 0 0;
            @include media($isPhone) {
                font-size: 0.875rem;
            }
        }
    }
}
</style>
