import Keycloak from "keycloak-js";

export const useKeycloak = async () => {
    const initOptions = {
        url: process.env.VUE_APP_KEYCLOAK_URL,
        realm: process.env.VUE_APP_KEYCLOAK_REALM,
        clientId: process.env.VUE_APP_KEYCLOAK_CLIENTID,
        onLoad: "login-required",
    };
    const keycloak = await Keycloak(initOptions);
    const auth = await keycloak.init({ onLoad: initOptions.onLoad });

    if (!auth) {
        keycloak.logout();
        return;
    }

    localStorage.setItem("accessToken", keycloak.token);

    return keycloak;
};
