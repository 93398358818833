<template>
    <Tray
        :top="68"
        :right="12"
        :open-percentage="divisionSwitchTrayOpenPercentage"
        @openPercentageChangeRequest="setDivisionSwitchTrayOpenPercentage"
    >
        <TreeView :items="treeData" :onClickHandler="switchDivision" :displayChildIcon="false" />
    </Tray>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import animateXtoY from "@feedbackcompany/feedback-company-vue-components/src/helpers/animationHelper";
import TreeView from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/TreeView.vue";
import Tray from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/Tray.vue";

export default {
    name: "DivisionwitchTray",
    components: {
        Tray,
        TreeView,
    },
    computed: {
        ...mapState({
            divisionSwitchTrayOpenPercentage: (state) =>
                state.AccountStore.divisionSwitchTrayOpenPercentage,
        }),
        ...mapGetters({
            treeData: "AccountStore/treeData",
        }),
    },
    methods: {
        ...mapActions({
            switchAccount: "AccountStore/switchAccount",
        }),
        setDivisionSwitchTrayOpenPercentage({ percentage }) {
            this.$store.commit("AccountStore/divisionSwitchTrayOpenPercentage", {
                openPercentage: percentage,
            });
        },
        closeDivisionSwitchTray() {
            if (window.innerWidth < 1001) {
                animateXtoY({
                    x: 1,
                    y: 0,
                    time: 300,
                    easeName: "easeOutQuad",
                    executable: ({ setTo }) => {
                        this.$store.commit("AccountStore/divisionSwitchTrayOpenPercentage", {
                            openPercentage: setTo,
                        });
                    },
                });
            } else {
                this.$store.commit("AccountStore/divisionSwitchTrayOpenPercentage", {
                    openPercentage: 0,
                });
            }
        },
        switchDivision(selectedItem) {
            if (!selectedItem.children && !selectedItem.isActive) {
                this.switchAccount({ uuid: selectedItem.uuid, apolloClient: this.$apollo });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";
</style>
