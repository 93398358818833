import axios from "axios";
import store from "@/store";
import util from "./util";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

function setInterceptors(apiClient) {
    if (localStorage.getItem("accessToken")) {
        apiClient.interceptors.request.use((request) => {
            request.headers.common["X-Tevreden-Host"] = store.state.AccountStore.user?.platform;
            request.headers.common.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
            return request;
        });
    }
    apiClient.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response.status === 401 || error.response.status === 403) {
                window.location.reload(true);
            } else {
                return Promise.reject(error);
            }
        }
    );
}

const dashboardApiClient = axios.create({
    baseURL: util.methods.getDashboardApiUrl(),
});
setInterceptors(dashboardApiClient);

const dashboardApiV2Client = axios.create({
    baseURL: util.methods.getDashboardApiV2Url(),
});
setInterceptors(dashboardApiV2Client);

const usersApiClient = axios.create({
    baseURL: util.methods.getUsersApiUrl(),
});
setInterceptors(usersApiClient);

const dataApiClient = axios.create({
    baseURL: util.methods.getDataApiUrl(),
});
setInterceptors(dataApiClient);

export { dashboardApiClient, dashboardApiV2Client, usersApiClient, dataApiClient };
