<template>
    <button class="menubar__button" @click="$emit('openMenu')">
        <font-awesome-icon :icon="['far', 'bars']" />
    </button>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/pro-regular-svg-icons";

library.add(faBars);

export default {
    name: "MobileMenuButton",
    components: { FontAwesomeIcon },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.menubar__button {
    all: revert;
    position: relative;
    top: 0;
    left: 0;
    z-index: 12;
    height: 100%;
    padding: 0 16px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    color: $black;
    background-color: transparent;

    &:active {
        color: $black;
    }

    &:focus,
    &:focus-within {
        outline: 0;
    }

    svg {
        width: 16px;
        height: 16px;
    }
}
</style>
