import i18n from "@/i18n";

class CustomError {
    constructor({ status, messages }) {
        this.status = status;
        this.messages = messages;
    }
}

export const responseHandler = async (response) => {
    const responseJson = await response.json();
    if (
        !responseJson ||
        !response.ok ||
        ("success" in responseJson && responseJson.success === false)
    ) {
        if (responseJson) {
            if (Array.isArray(responseJson.error)) {
                throw new CustomError({
                    status: response.status,
                    messages: responseJson.error,
                });
            } else {
                throw new CustomError({
                    status: response.status,
                    messages: [responseJson.error],
                });
            }
        } else {
            throw new Error({
                status: response.status,
                messages: [i18n.t("cp__generic__error")],
            });
        }
    }
    return responseJson;
};

export const errorHandler = (error) => {
    let returnErrors;
    if (error.messages) {
        returnErrors = error;
    } else {
        returnErrors = {
            status: 400,
            messages: [i18n.t("cp__generic__error")],
        };
    }
    return returnErrors;
};
