import i18n from "@/i18n";

const LanguageStore = {
    namespaced: true,
    state: {
        currentLanguage: i18n.locale,
        languages: {
            en: "English",
            nl: "Nederlands",
        },
        languageTrayOpenPercentage: 0,
    },
    mutations: {
        setLanguage(state, { language }) {
            state.currentLanguage = language;
            localStorage.setItem("language", language);
            i18n.locale = language;
            document.documentElement.setAttribute("lang", language);
        },
        setLanguageTrayOpenPercentage(state, { openPercentage }) {
            if (openPercentage !== undefined && this.state.trayDragAllowed) {
                state.languageTrayOpenPercentage = openPercentage;
            }
        },
    },
    getters: {
        getLanguage() {
            return i18n.locale;
        },
        getLanguageName(state) {
            return state.languages[i18n.locale];
        },
        getLanguageCultureCode() {
            switch (i18n.locale) {
                case "nl":
                    return "nl-NL";
                case "en":
                    return "en-GB";
                default:
                    return "en-GB";
            }
        },
    },
};

export default LanguageStore;
