<template>
    <Tray
        :top="68"
        :right="12"
        :open-percentage="subNavigationTrayOpenPercentage"
        @openPercentageChangeRequest="setSubNavigationTrayOpenPercentage"
    >
        <div class="container">
            <DashboardsList />
        </div>
    </Tray>
</template>

<script>
import { mapState } from "vuex";
import Tray from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/Tray.vue";
import DashboardsList from "@/components/Dashboards/DashboardsList.vue";

export default {
    name: "SubNavigationSwitchTray",
    components: {
        Tray,
        DashboardsList,
    },
    computed: {
        ...mapState({
            subNavigationTrayOpenPercentage: (state) =>
                state.AccountStore.subNavigationTrayOpenPercentage,
        }),
    },
    methods: {
        setSubNavigationTrayOpenPercentage({ percentage }) {
            this.$store.commit("AccountStore/subNavigationTrayOpenPercentage", {
                openPercentage: percentage,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    padding: 32px 24px;
}
</style>
