<template>
    <DesktopMenuBar>
        <div class="menubar-content">
            <LanguageSwitcherButton class="menubar_item" />
            <UserHeaderButton />
        </div>
    </DesktopMenuBar>
</template>

<script>
import DesktopMenuBar from "@feedbackcompany/feedback-company-vue-components/src/components/organisms/Layout/DesktopMenuBar.vue";
import UserHeaderButton from "@/components/Layout/Atoms/UserHeaderButton.vue";
import LanguageSwitcherButton from "@/components/Global/Molecules/LanguageSwitcherButton.vue";

export default {
    name: "DesktopMenubar",
    components: {
        DesktopMenuBar,
        UserHeaderButton,
        LanguageSwitcherButton,
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.menubar-content {
    display: flex;
    justify-content: center;
    align-items: center;
}
.menubar_item {
    margin-right: 20px;
}
</style>
