<template>
    <div id="app_container">
        <NotificationRail :notificationList="pushNotificationList" />
        <div v-if="userIsMissingParentEntity">
            <ParentEntityMissingError v-if="userIsMissingParentEntity" />
            <LogoutTray :isMobileView="windowWidth < 1001" id="account-switch-tray" />
        </div>
        <BlockingError
            v-else-if="
                !userIsMissingParentEntity &&
                blockingErrors &&
                blockingErrors.length > 0 &&
                !isLoading
            "
            :code="blockingErrors[0]"
            message="GraphQL Error"
        />
        <div v-else>
            <portal-target name="popups_container" />
            <AccountSwitchPopup v-if="accountSwitchPopup" />
            <div v-if="showMenu" id="app">
                <!-- When user is logged in -->
                <MobileMenubar v-if="windowWidth < 1001" class="menubar" />
                <DesktopMenubar v-else class="menubar" />
                <Navigation class="navigation" @refreshView="refreshView" />
                <router-view
                    class="view"
                    v-if="!isLoading"
                    :key="(activeAccount.slug || activeUser.username) + keyId"
                />
                <LogoutTray :isMobileView="windowWidth < 1001" id="account-switch-tray" />
                <SubNavigationSwitchTray
                    v-if="windowWidth < 1001"
                    id="sub-navigation-switch-tray"
                />
                <DivisionSwitchTray id="division-switch-tray" />
                <NotificationViewer id="notification-viewer" />
            </div>
            <div v-else id="app_loader">
                <LoaderCircular />
            </div>
        </div>
        <LanguageSwitcher id="language-switcher" />
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { vueWindowSizeMixin } from "vue-window-size";
import BlockingError from "@feedbackcompany/feedback-company-vue-components/src/components/organisms/BlockingError.vue";
import NotificationRail from "@feedbackcompany/feedback-company-vue-components/src/components/organisms/NotificationRail.vue";
import animateXtoY from "@feedbackcompany/feedback-company-vue-components/src/helpers/animationHelper";
import LoaderCircular from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/LoaderCircular.vue";
import router from "@/router";
import Navigation from "@/components/Layout/Navigation.vue";
import LogoutTray from "@/components/Layout/Molecules/LogoutTray.vue";
import ParentEntityMissingError from "@/components/Error/ParentEntityMissingError.vue";
import SubNavigationSwitchTray from "@/components/Layout/Molecules/SubNavigationSwitchTray.vue";
import DivisionSwitchTray from "@/components/Layout/Molecules/DivisionSwitchTray.vue";
import NotificationViewer from "@/components/Layout/Molecules/NotificationViewer.vue";
import LanguageSwitcher from "@/components/Layout/Molecules/LanguageSwitcher.vue";
import MobileMenubar from "@/components/Layout/MobileMenubar.vue";
import DesktopMenubar from "@/components/Layout/DesktopMenubar.vue";
import AccountSwitchPopup from "@/components/Global/Molecules/AccountSwitchPopup.vue";

export default {
    name: "App",
    mixins: [vueWindowSizeMixin],
    components: {
        MobileMenubar,
        DesktopMenubar,
        Navigation,
        LogoutTray,
        SubNavigationSwitchTray,
        DivisionSwitchTray,
        NotificationViewer,
        LanguageSwitcher,
        LoaderCircular,
        AccountSwitchPopup,
        BlockingError,
        NotificationRail,
        ParentEntityMissingError,
    },
    data() {
        return {
            componentLabels: [
                {
                    key: "cp__generic__error",
                    sub: "Oops, something went wrong, please try again.",
                },
            ],
            startedAccountCheck: false,
            isLoading: true,
            keyId: 0,
        };
    },
    computed: {
        ...mapState({
            errors: (state) => state.AccountStore.errors.getUser,
            divisions: (state) => state.AccountStore.divisions,
            user: (state) => state.AccountStore.user,
            accountSwitchPopup: (state) => state.AccountStore.forcedSwitchPopupOpen,
            pushNotificationList: (state) => state.notificationStore.all,
        }),
        ...mapGetters({
            activeAccount: "AccountStore/activeAccount",
            activeUser: "AccountStore/activeUser",
        }),
        userIsMissingParentEntity() {
            // There is no user in memory (yet). Cannot verify parent's existence.
            if (!this.user) return false;
            if (this.user.isAppreciation && !this.user.isEvaluation) {
                return this.divisions.length === 0;
            }
            if (!this.user.isAppreciation && this.user.isEvaluation) {
                return !this.user.platform;
            }
            if (this.user.isAppreciation && this.user.isEvaluation) {
                return !this.user.platform && this.divisions.length === 0;
            }
            return true;
        },
        blockingErrors() {
            return this.authorizationError ? [] : this.errors;
        },
        showMenu() {
            if (!this.activeUser) return false;
            return this.activeUser.email !== undefined;
        },
        authorizationError() {
            return this.errors.includes("UNAUTHORIZED");
        },
    },
    watch: {
        windowHeight() {
            this.setViewportHeightCSSVar();
        },
    },
    methods: {
        ...mapActions({
            reLoginToOldPortal: "AccountStore/reLoginToOldPortal",
        }),
        setViewportHeightCSSVar() {
            const windowHeight = window.visualViewport
                ? window.visualViewport.height
                : window.innerHeight;
            const vh = windowHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            document.documentElement.style.setProperty("--nav_width", "248px");
        },
        refreshView() {
            this.keyId += 1;
        },
    },
    async created() {
        await this.$store.dispatch("AccountStore/getCurrentUser", { apolloClient: this.$apollo });
        await this.$store.dispatch("NotificationStore/getNotificationCount");

        if (this.activeUser.isEvaluation) {
            await this.$store.dispatch("AccountStore/getDashboards", {
                apolloClient: this.$apollo,
            });
        }

        if (this.activeUser.isAppreciation && !this.userIsMissingParentEntity) {
            await this.$store.dispatch("AccountStore/reLoginToOldPortal");
        }

        setInterval(() => {
            this.$store.dispatch("AccountStore/refreshAuthToken");
        }, 10000);

        window.addEventListener("focus", () => {
            if (!this.$store.state.AccountStore.keycloak.isTokenExpired(5)) return;
            this.$store.dispatch("AccountStore/refreshAuthToken");
        });

        this.isLoading = false;
    },
    mounted() {
        // Receiving data from Legacy Portal
        let touchStartData;
        const receiveMessage = ({ data, origin }) => {
            if (origin.toString() !== process.env.VUE_APP_API_BASE_URL) return;

            switch (data.type) {
                case "touchEvent": {
                    const { touchEvent } = data;
                    const { type } = touchEvent;
                    const { touchData } = touchEvent;
                    if (type === "touchstart" && touchData.clientX < 50) touchStartData = touchData;
                    if (!touchStartData) return;
                    const deltaX = touchData.clientX - touchStartData.clientX;
                    let openPercentage = deltaX / 248;
                    if (openPercentage > 1) openPercentage = 1;
                    if (openPercentage < 0) openPercentage = 0;

                    if (type === "touchmove") {
                        this.$store.commit("navigation", {
                            openPercentage,
                        });
                    }
                    if (type === "touchend") {
                        const open = openPercentage > 0.35;
                        const goal = open ? 1 : 0;
                        animateXtoY({
                            x: openPercentage,
                            y: goal,
                            time: 160,
                            executable: ({ setTo }) => {
                                this.$store.commit("navigation", {
                                    openPercentage: setTo,
                                });
                            },
                        });
                        touchStartData = null;
                    }
                    break;
                }
                case "userNavigation": {
                    const navigatingTo = data.navigatingTo.replace(
                        process.env.VUE_APP_API_BASE_URL,
                        ""
                    );
                    if (navigatingTo.indexOf("https://") > -1) {
                        // External Links
                        window.open(data.navigatingTo, "_blank");
                    } else if (router.currentRoute.fullPath !== navigatingTo) {
                        // Internal Links
                        router.push(navigatingTo);
                    } else {
                        // The Same Link. If this is not done Vue Errors.
                        window.location.reload();
                    }
                    break;
                }
                case "iframeHeightChange": {
                    if (this.$store.state.iframeHeight === data.height) return;
                    this.$store.commit("iframeHeight", data.height);
                    break;
                }
                default: {
                    break;
                }
            }
        };
        window.addEventListener("message", receiveMessage, false);

        // Fix for consistent Viewport Height on Mobile,
        // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        this.setViewportHeightCSSVar();
        window.addEventListener("resize", () => {
            this.setViewportHeightCSSVar();
            // iOS Safari Fix
            window.setTimeout(() => {
                this.setViewportHeightCSSVar();
            }, 100);
        });
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";
body {
    overflow-x: hidden;
}
#app {
    width: 100vw;
    @include viewportHeight(100, 0);
    overscroll-behavior: none;
    overflow: hidden;
    display: block;
    padding-top: 48px;
    background-color: $grey_bg;
    @media (min-width: 1001px) {
        padding-top: 0px;
        display: grid;
        grid-template-columns: $nav_width auto;
        grid-template-areas:
            "menubar menubar"
            "sidebar content";
    }
}
#app_loader {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menubar {
    width: 100%;
    grid-area: menubar;
    @media (max-width: 1000px) {
        position: fixed;
        top: 0px;
    }
}
.navigation {
    width: $nav_width;
    flex-shrink: 0;
    position: fixed;
    grid-area: sidebar;
    @media (min-width: 1001px) {
        position: sticky;
    }
}
.view {
    overflow-y: auto;
    grid-area: content;
    width: 100%;
}
</style>

<style lang="scss">
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_typography_classes";

html,
body {
    margin: 0px;
    padding: 0px;
    overscroll-behavior: none;
    overflow: hidden;
}
* {
    box-sizing: border-box;
}
input {
    -webkit-appearance: none;
}
.tippy-tooltip {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0px;
}
</style>
