/* eslint-disable import/no-import-module-exports */
import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./en.yaml";
import nl from "./nl.yaml";

Vue.use(VueI18n);
let language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);
// If the browser language isn't one of our supported languages for the Customer Portal use english.
if (["en", "nl"].indexOf(language) < 0) language = "en";

const i18n = new VueI18n({
    locale: language,
    fallbackLocale: "en",
    warnHtmlInMessage: "off",
    silentTranslationWarn: true,
    messages: {
        nl,
        en,
    },
});

if (module.hot) {
    module.hot.accept(["./en.yaml", "./nl.yaml"], () => {
        // eslint-disable-next-line global-require
        i18n.setLocaleMessage("en", require("./en.yaml"));
        // eslint-disable-next-line global-require
        i18n.setLocaleMessage("nl", require("./nl.yaml"));
    });
}

export default i18n;
