<template>
    <div
        class="account_image"
        :class="{
            'account_image--failed_loading': showInitial,
        }"
        :style="imageStyle"
    >
        <img
            alt="Image"
            v-if="src"
            class="account_image__loader"
            @error="onImageLoadError"
            :src="src"
        />
        <span v-if="showInitial">{{ initial.toUpperCase() }}</span>
    </div>
</template>

<script>
export default {
    name: "AccountImage",
    props: {
        initial: {
            type: String,
            required: false,
            validator(value) {
                return value.length === 1;
            },
        },
        src: {
            required: false,
        },
        size: {
            type: Number,
            default: 42,
        },
    },
    watch: {
        src() {
            this.error = false;
        },
    },
    data() {
        return {
            error: false,
        };
    },
    computed: {
        imageStyle() {
            if (!this.showInitial) {
                return {
                    backgroundImage: `url(${this.src})`,
                    backgroundColor: "#ffffff",
                    width: `${this.size}px`,
                    height: `${this.size}px`,
                };
            }
            return {
                backgroundColor: this.backgroundColor,
                width: `${this.size}px`,
                height: `${this.size}px`,
            };
        },
        showInitial() {
            return !this.src || this.error;
        },
        backgroundColor() {
            let returnColor = "#2E976E";
            const colors = [
                { color: "#1D59B3", match: "A-D" },
                { color: "#C08B0D", match: "E-H" },
                { color: "#B3523E", match: "I-L" },
                { color: "#2E976E", match: "M-P" },
                { color: "#2781A9", match: "Q-T" },
                { color: "#7327A9", match: "U-Z" },
            ];
            colors.forEach((colorRegexMatch) => {
                if (new RegExp(`[${colorRegexMatch.match}]`, "i").test(this.initial)) {
                    returnColor = colorRegexMatch.color;
                }
            });
            return returnColor;
        },
    },
    methods: {
        onImageLoadError() {
            this.error = true;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.account_image {
    margin: 0px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border-radius: 4px;
    border: 1px solid $grey_alabaster;
    overflow: hidden;
    color: $white;
    transition: all 0.2s;
    flex-shrink: 0;
}
.account_image--failed_loading {
    @extend %body2_style;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $blue_sea;
}
.account_image__loader {
    opacity: 0;
    display: none;
}
</style>
