<template>
    <InboxItemBase v-bind="$props">
        <template v-slot:icons>
            <font-awesome-icon
                v-if="published"
                :title="$t('cp__inbox__status__approved')"
                class="inbox_item__published_status_icon inbox_item__published_status_icon--published"
                :icon="['fas', 'check-circle']"
            />
            <font-awesome-icon
                v-if="!published"
                :title="$t('cp__inbox__status__on_hold')"
                class="inbox_item__published_status_icon inbox_item__published_status_icon--unpublished"
                :icon="['fas', 'clock']"
            />
            <font-awesome-icon
                :title="$t('cp__inbox__filter__type__company_review')"
                class="inbox_item__review_type_icon"
                :icon="['far', 'store']"
            />
            <font-awesome-icon
                v-if="hasUploadedImage"
                :title="$t('cp__inbox__filter__has__uploaded_image')"
                class="inbox_item__image_icon"
                :icon="['far', 'image']"
            />
            <font-awesome-icon
                v-if="revised"
                :title="$t('cp__inbox__status__revised')"
                class="inbox_item__revised_status_icon"
                transform="flip-h"
                :icon="['fas', 'undo-alt']"
            />
            <font-awesome-icon
                v-if="replied"
                :title="$t('cp__inbox__status__replied')"
                class="inbox_item__replied_status_icon"
                :icon="['fas', 'reply']"
            />
            <Stars :size="16" :rating="rating" />
        </template>
    </InboxItemBase>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle, faClock, faReply, faUndoAlt } from "@fortawesome/pro-solid-svg-icons";
import { faStore, faImage } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Stars from "@/components/Inbox/Atoms/Stars.vue";
import { baseProps, default as InboxItemBase } from "./InboxItemBase.vue";

library.add(faCheckCircle, faClock, faStore, faReply, faUndoAlt, faImage);

export default {
    name: "InboxItemMerchantReview",
    components: {
        InboxItemBase,
        Stars,
        FontAwesomeIcon,
    },
    props: {
        ...baseProps,
        rating: {
            type: Number,
            required: true,
            default: 1,
        },
    },
    data() {
        return {
            hover: false,
        };
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.inbox_item__published_status_icon,
.inbox_item__revised_status_icon,
.inbox_item__review_type_icon,
.inbox_item__replied_status_icon {
    color: $grey_mid;
    font-size: 1rem;
    margin-right: 12px;
}
.inbox_item__published_status_icon--published {
    color: $green;
}
.inbox_item__published_status_icon--unpublished {
    color: $red;
}
.inbox_item__image_icon {
    color: $grey_mid;
    margin-right: 12px;
}
</style>
