import queryString from "query-string";
import Vue from "vue";
import * as Sentry from "@sentry/vue";
import requestURLBuilder from "./requestURLBuilder";
import { responseHandler, errorHandler } from "./httpRequestHandlers";

export default ({ url, body, urlParams, noAuthHeader }) =>
    new Promise((resolve, reject) => {
        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
        };
        if (!noAuthHeader) {
            if (Vue.prototype.store.state.AccountStore.keycloak.isTokenExpired(5)) {
                Vue.prototype.store.state.AccountStore.keycloak.logout();
            }

            headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
            headers["X-Shop-Uuid"] = localStorage.getItem("accountUuid");
        }

        // Build the URL.
        const requestUrl = requestURLBuilder({ url, urlParams });
        fetch(requestUrl, {
            method: "POST",
            body: queryString.stringify(body),
            credentials: "include",
            headers,
        })
            .then(async (response) => responseHandler(response))
            .then((response) => resolve(response))
            .catch(async (error) => {
                Sentry.captureException(error);
                reject(await errorHandler(error));
            });
    });
