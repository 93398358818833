<template>
    <Tray
        targetElementSelector=".language_switcher_button"
        :open-percentage="languageTrayOpenPercentage"
        @openPercentageChangeRequest="onOpenPercentageChangeRequest"
        :left="left"
    >
        <div class="language_switcher">
            <h6 class="headline6 language_switcher__title">
                {{ $t("cp__language_switcher__title") }}
            </h6>
            <ul class="language_switcher__languages_list">
                <li
                    v-for="(language, key) in languages"
                    :key="key"
                    class="language_switcher__language"
                    :class="{
                        'language_switcher__language--selected': key === currentLanguage,
                    }"
                    @click="onLanguageClick({ language: key })"
                >
                    {{ language }}
                    <span v-if="key === currentLanguage" class="language_switcher__language_after">
                        <div v-if="!loadingLanguage" class="language_switcher__selected_icon">
                            <font-awesome-icon :icon="['fas', 'check']" />
                        </div>
                        <LoaderCircular v-else class="language_switcher__loader" :height="18" />
                    </span>
                </li>
            </ul>
        </div>
    </Tray>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import LoaderCircular from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/LoaderCircular.vue";
import Tray from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/Tray.vue";

library.add(faCheck);

export default {
    name: "notificationTray",
    data() {
        return {
            left: 0,
            loadingLanguage: false,
        };
    },
    components: {
        FontAwesomeIcon,
        Tray,
        LoaderCircular,
    },
    computed: {
        ...mapState({
            languageTrayOpenPercentage: (state) => state.LanguageStore.languageTrayOpenPercentage,
            languages: (state) => state.LanguageStore.languages,
            currentLanguage: (state) => state.LanguageStore.currentLanguage,
        }),
        headerLabel() {
            return this.$t("cp__language_switcher__title");
        },
    },
    methods: {
        ...mapMutations({
            setLanguageTrayOpenPercentage: "LanguageStore/setLanguageTrayOpenPercentage",
            setLanguage: "LanguageStore/setLanguage",
        }),
        onOpenPercentageChangeRequest({ percentage }) {
            this.setLanguageTrayOpenPercentage({
                openPercentage: percentage,
            });
        },
        onLanguageClick({ language }) {
            this.loadingLanguage = true;
            this.setLanguage({ language });
        },
    },
    watch: {
        languageTrayOpenPercentage() {
            const bellEl = document.getElementsByClassName("language_switcher_button")[0];
            this.left = bellEl.offsetLeft - (256 - bellEl.offsetWidth);
        },
        headerLabel() {
            this.loadingLanguage = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";
.language_switcher {
    padding: 0;
}
.language_switcher__title {
    padding: 0 16px;
}
.language_switcher__languages_list {
    padding: 0px;
    margin: 0px;
    list-style: none;
    margin-top: 20px;
}
.language_switcher__language {
    @extend %body1_style;
    padding: 16px;
    margin: 0px;
    display: flex;
    align-items: center;
    &:hover {
        background-color: #f5f9fe;
        cursor: pointer;
    }
}
.language_switcher__language_after {
    display: flex;
    align-items: center;
}
.language_switcher__language--selected {
    background-color: #f5f9fe;
    font-weight: 700;
}
.language_switcher__selected_icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background: $blue;
    width: 16px;
    height: 16px;
    font-size: 8px;
    border-radius: 50%;
    margin-left: 12px;
    box-shadow: 0 0 15px 0 rgba(67, 139, 248, 0.25);
}
.language_switcher__loader {
    display: inline-flex;
    margin-left: 12px;
}
</style>
