<template>
    <div
        class="division_switch_button"
        :class="{ only_one_division: oneDivision }"
        @click="toggleDivisionSwitcher"
    >
        <AccountImage :src="activeAccount.logo" :size="28" :initial="accountName[0]" />
        <div class="division_switch_button__name" v-line-clamp:2="2">
            {{ accountName }}
        </div>
        <div v-if="!oneDivision" class="arrow">
            <font-awesome-icon
                class="division_switch_button__icon"
                :class="{
                    'division_switch_button__icon--flipped': divisionSwitcherIsOpen,
                }"
                :icon="['far', 'chevron-right']"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import AccountImage from "@/components/Global/Atoms/AccountImage.vue";

library.add(faChevronRight);

export default {
    name: "DivisionSwitchButton",
    components: {
        FontAwesomeIcon,
        AccountImage,
    },
    props: {
        divisionSwitcherIsOpen: {
            type: Boolean,
            default: true,
        },
        oneDivision: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            activeAccount: "AccountStore/activeAccount",
            accountName: "AccountStore/accountName",
        }),
    },
    methods: {
        toggleDivisionSwitcher() {
            if (!this.oneDivision) {
                this.$emit("isDivisionSwitcherOpen", !this.divisionSwitcherIsOpen);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.division_switch_button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
}
.only_one_division {
    pointer-events: none;
}
.division_switch_button__name {
    @extend %body2_style;
    font-weight: 600;
    max-width: 200px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 16px;
    width: 100%;
    word-break: break-word !important; // to override v-line-clamp directive's inline styling
}
.division_switch_button__icon {
    flex-shrink: 0;
    font-size: 12px;
    margin-left: 15px;
}
.division_switch_button__icon--flipped {
    transform: rotateZ(180deg);
}
.arrow {
    float: right;
}
</style>
