<template>
    <div class="chart-export">
        <widget-chart
            v-if="chartOptions != null"
            :chart-options="chartOptions"
            ref="widgetChart"
        ></widget-chart>
        <div id="done" v-if="done">
            <span id="contains-data">{{ containsData }}</span>
            <span id="image-data">{{ imageData }}</span>
        </div>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { dashboardApiClient } from "@/helpers/evaluation/apiclients";
import { chartService, chartExportService, splitByService } from "@/services/Evaluation";
import WidgetChart from "@/components/Evaluation/Dashboards/widget/Chart.vue";
import util from "@/helpers/evaluation/util";

export default {
    props: ["encryptedWidgetId", "platform"],
    mixins: [util, validationMixin],
    components: {
        WidgetChart,
    },
    data() {
        return {
            loaded: false,
            chartExportData: null,
            splitBy: false,
            widgetId: null,
            chartOptions: null,
            imageData: "",
            containsData: false,
            done: false,
        };
    },
    computed: {
        splitByPropertiesLoaded() {
            return this.$store.state.DashboardStore.splitByPropertiesLoaded;
        },
    },
    created() {
        this.getChartExportData();
    },
    methods: {
        getChartExportData() {
            delete dashboardApiClient.defaults.headers.common.Authorization;
            dashboardApiClient
                .get(`/chart-exports/${this.encryptedWidgetId}`, {
                    headers: { "X-Tevreden-Host": this.platform },
                })
                .then((response) => {
                    this.chartExportData = response.data;
                    this.splitBy =
                        this.chartExportData.widget.splitBys &&
                        this.chartExportData.widget.splitBys.length > 0;
                    if (this.splitBy) {
                        splitByService.initProperties(this.encryptedWidgetId);
                    } else {
                        this.setChartData();
                    }
                })
                .catch(() => {
                    this.done = true;
                });
        },
        setChartData() {
            const self = this;
            const widgetData = chartService.getWidgetData(
                this.$i18n,
                this.chartExportData.widget,
                this.chartExportData.chart,
                false,
                false,
                "col-md-12"
            );
            if (this.splitBy) {
                splitByService.storeProperties(this.encryptedWidgetId);
            }
            widgetData.chartConfig.chart.events = {
                load() {
                    self.loaded = true;
                },
            };
            this.widgetId = widgetData.widget.id;
            this.chartOptions = widgetData.chartConfig;
        },
    },
    watch: {
        splitByPropertiesLoaded() {
            this.setChartData();
        },
        loaded(chartLoaded) {
            if (chartLoaded) {
                const { chart } = this.$refs.widgetChart.$refs.chart;
                if (chart.series.length > 0) {
                    const charts = {};
                    charts[this.widgetId] = chart;
                    chartExportService.getChartsBase64Data(
                        charts,
                        (chartsData) => {
                            this.imageData = chartsData[this.widgetId];
                            this.containsData = true;
                            this.done = true;
                        },
                        this.$i18n
                    );
                } else {
                    this.done = true;
                }
            }
        },
    },
};
</script>
