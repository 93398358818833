/* eslint-disable no-return-assign */
/* eslint-disable no-sequences */
// Load and Error State.
// I saw that I'm going to have to repeat this logic a lot, so I made a generic function for it.

import httpGet from "@/helpers/httpGet";

const mutations = {
    addErrors(state, { name, errors }) {
        state.errors[name] = errors;
    },
    clearErrors(state, { name }) {
        state.errors[name] = [];
    },
    setLoading(state, { name, loading }) {
        state.loading[name] = loading;
    },
    setSuccessfulRequests(state, { name, success }) {
        state.successfulRequests[name] = success;
    },
    set(state, { name, value }) {
        state[name] = value;
    },
};

export { mutations };

/* Loadable
 * Loadable is an object containing:
 *  - a required string name
 *  - an optional string endpoint => the generator uses this to generate a get call automatically
 */
// eslint-disable-next-line func-names
export default function ({ loadables }) {
    const statuses = [
        { name: "errors", default: [] },
        { name: "loading", default: false },
        { name: "successfulRequest", default: false },
    ];
    /*
     * Creates State Object based on Statuses.
     *
     * Status object becomes tree nested objects with the name property being the key name of each object.
     * Those tree nested object contain the loadables as their children, each loadable's value being the default param from the Status object
     * Also in the main object it returns it has the loadable values as keys,
     * so the object will look like: {
     *      errors: {
     *          loadable1: [],
     *          loadable2: [],
     *          ...
     *      },
     *      loading: {
     *          loadable1: false,
     *          loadable2: false,
     *          ...
     *      },
     *      successfulRequest: {
     *          loadable1: false,
     *          loadable2: false,
     *          ...
     *      },
     *      loadable1: null,
     *      loadable2: null,
     * }
     *
     * Other then that, if apiURL is defined in the loadables, this function will generate GET calls.
     */
    const generateGetFunction = ({ name, endpoint, urlParams }) => ({
        executable: ({ commit }) => {
            commit("clearErrors", { name });
            commit("setLoading", { name, loading: true });
            httpGet({
                url: `${process.env.VUE_APP_API_BASE_URL}${endpoint}`,
                urlParams,
            })
                .then(({ data }) => {
                    commit("set", { name, value: data });
                })
                .catch((errors) => {
                    commit("setSuccessfulRequests", { name, success: false });
                    commit("addErrors", {
                        name,
                        errors,
                    });
                    /* eslint-disable no-console */
                    console.log(errors);
                })
                .finally(() => {
                    commit("setLoading", { name, loading: false });
                });
        },
    });
    const actions = {
        ...loadables
            .filter((loadable) => loadable.endpoint)
            .reduce((a, loadable) => {
                const { name, endpoint } = loadable;
                const getName = `get${name.charAt(0).toUpperCase() + name.slice(1)}`;
                a[getName] = generateGetFunction({ name, endpoint }).executable;
                return a;
            }, {}),
    };

    const state = {
        ...statuses.reduce(
            (accumulativeObject, object) => (
                (accumulativeObject[object.name] = {
                    ...loadables.reduce(
                        (a, loadable) => ((a[loadable.name] = object.default), a),
                        {}
                    ),
                }),
                accumulativeObject
            ),
            {}
        ),
        ...loadables.reduce((a, loadable) => ((a[loadable.name] = null), a), {}),
    };
    return {
        namespaced: false,
        state,
        mutations,
        actions,
    };
}
