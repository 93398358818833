import Vue from "vue";
import Vuex from "vuex";
import { detect } from "detect-browser";
import notificationStore from "@feedbackcompany/feedback-company-vue-components/src/stores/notificationStore";
import errorStore from "@feedbackcompany/feedback-company-vue-components/src/stores/errorStore";
import AccountStore from "@/stores/AccountStore";
import DashboardStore from "@/stores/evaluation/DashboardStore";
import NotificationStore from "@/stores/NotificationStore";
import InboxStore from "@/stores/InboxStore";
import CompanyReviewStore from "@/stores/CompanyReviewStore";
import ProductReviewStore from "@/stores/ProductReviewStore";
import ProductQuestionStore from "@/stores/ProductQuestionStore";
import LanguageStore from "@/stores/LanguageStore";
import UserConfig from "@/stores/UserConfig";
import ResearchInvitationsStore from "@/stores/ResearchInvitationsStore";
import DownloadReportsStore from "@/stores/DownloadReportsStore";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        AccountStore,
        DashboardStore,
        NotificationStore,
        notificationStore,
        InboxStore,
        LanguageStore,
        CompanyReviewStore,
        ProductReviewStore,
        ProductQuestionStore,
        UserConfig,
        ResearchInvitationsStore,
        DownloadReportsStore,
        errorStore,
    },
    state: {
        navigation: {
            openPercentage: 0,
        },
        iframeHeight: 1000,
        trayDragAllowed: true,
        browserInfo: detect(),
    },
    mutations: {
        navigation(state, { openPercentage, open }) {
            if (openPercentage !== undefined && this.state.trayDragAllowed) {
                state.navigation.openPercentage = openPercentage;
            }
            if (open) state.navigation.open = open;
        },
        iframeHeight(state, height) {
            state.iframeHeight = height;
        },
        setTrayDragAllowed(state, { trayDragAllowed }) {
            state.trayDragAllowed = trayDragAllowed;
        },
    },
    actions: {
        /*
         * Every store that needs updating when an account is switched,
         * should have an 'onAccountSwitch' action.
         * this action here triggers all those actions when an account is switched.
         * This action is called from the AccountStore.
         * This needs to be optimized so that not every call triggers when an account is switched
         */
        onAccountSwitch({ dispatch }) {
            const onAccountSwitchActions = Object.keys(this._actions).filter(
                (action) => action.indexOf("/onAccountSwitch") > -1
            );
            onAccountSwitchActions.forEach((onAccountSwitchAction) => {
                dispatch(onAccountSwitchAction);
            });
        },
        pushNotification({ commit }, { type, message, title = null }) {
            commit("pushNotification", {
                type,
                message,
                customTitle: title,
            });
        },
        setBlockingErrors({ commit }, errorsArray) {
            commit("setBlockingErrors", errorsArray);
        },
        removeAllBlockingErrors({ commit }) {
            commit("removeAllBlockingErrors");
        },
        removeBlockingError({ commit }, errId) {
            commit("removeBlockingError", errId);
        },
    },
});

// return store;
// };
//
// export default function () {
//     let store = null;
//
//     const init = async (keycloak) => {
//         if (store) return store;
//
//         AccountStore.state.keycloak = keycloak;
//
//         // @todo: remove the async nature of this initialisation once we can replace the websocket connection in InvitationsStore.
//         const InvitationsStore = await getInvitationsStore();
//
//         store =
//
//     return { init, store };
// }
