<template>
    <Tray
        :top="68"
        :right="24"
        :open-percentage="logoutTrayOpenPercentage"
        @openPercentageChangeRequest="setLogoutTrayOpenPercentage"
    >
        <div class="user_tray__content">
            <header>
                <div class="user_tray__info">
                    <AccountImage class="user_tray__info__image" :initial="userInfo[0]" />
                </div>
                <div class="user_tray__info__name">{{ userInfo }}</div>
                <div v-if="activeUser.email" class="user_tray__info__name email">
                    {{ activeUser.email }}
                </div>
                <div class="user_tray__devider" />
            </header>
            <div v-if="isMobileView" class="user_tray__actions_language">
                <LanguageSwitcherButton class="language_switcher" />
            </div>
            <div class="user_tray__actions" :class="{ user_tray__actions_logout: isMobileView }">
                <button @click="submitLogout">
                    {{ $t("cp__account_switcher__logout") }}
                </button>
                <div v-if="isMobileView" class="user_tray__actions_feedback_company">
                    2010 – 2021© Feedback Company
                </div>
            </div>
        </div>
    </Tray>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import animateXtoY from "@feedbackcompany/feedback-company-vue-components/src/helpers/animationHelper";
import Tray from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/Tray.vue";
import AccountImage from "@/components/Global/Atoms/AccountImage.vue";
import LanguageSwitcherButton from "@/components/Global/Molecules/LanguageSwitcherButton.vue";

export default {
    name: "LogoutTray",
    components: {
        Tray,
        AccountImage,
        LanguageSwitcherButton,
    },
    props: {
        isMobileView: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            activeUser: "AccountStore/activeUser",
        }),
        ...mapState({
            logoutTrayOpenPercentage: (state) => state.AccountStore.logoutTrayOpenPercentage,
        }),
        userInfo() {
            return this.activeUser.firstName && this.activeUser.lastName
                ? `${this.activeUser.firstName} ${this.activeUser.lastName}`
                : this.activeUser.username;
        },
    },
    methods: {
        ...mapActions({
            logout: "AccountStore/logout",
        }),
        submitLogout() {
            this.logout({ redirectToLogin: true });
            this.closeUserTray();
        },
        setLogoutTrayOpenPercentage({ percentage }) {
            this.$store.commit("AccountStore/logoutTrayOpenPercentage", {
                openPercentage: percentage,
            });
        },
        closeUserTray() {
            // When notification is selected, both the mobile menu and the nav tray should collapse
            // that is what this function facilitates
            if (window.innerWidth < 1001) {
                animateXtoY({
                    x: 1,
                    y: 0,
                    time: 300,
                    easeName: "easeOutQuad",
                    executable: ({ setTo }) => {
                        this.$store.commit("AccountStore/logoutTrayOpenPercentage", {
                            openPercentage: setTo,
                        });
                        this.$store.commit("navigation", {
                            openPercentage: setTo,
                        });
                    },
                });
            } else {
                this.$store.commit("AccountStore/logoutTrayOpenPercentage", {
                    openPercentage: 0,
                });
                this.$store.commit("navigation", {
                    openPercentage: 0,
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

$spacing: 16px;

.user_tray__info {
    width: 100%;
    padding-bottom: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:first-of-type {
        padding-top: 24px;
    }
}
.user_tray__actions {
    display: grid;
    padding: 24px;
    padding-top: 0px;
    justify-content: center;
    button {
        @extend %body2_style;
        background-color: $white;
        font-size: 0.75em;
        font-weight: 700;
        width: 202px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        padding: 8px 0;
        border: 1px solid $grey_alabaster;
        border-radius: 4px;
        &:hover {
            cursor: pointer;
            background: $grey_alabaster;
            border-color: $grey_athens;
        }
    }
}
.user_tray__info__image {
    flex-shrink: 0;
    border: 1px solid $grey_alabaster;
    width: 56px;
    height: 56px;
    overflow: hidden;
}
.user_tray__info__name {
    @extend %body2_style;
    font-weight: 600;
    padding: 0px 24px 7px 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    text-align: center;
}
.user_tray__actions_language {
    display: flex;
    padding: 0px 24px 24px 24px;
    justify-content: center;
    button {
        @extend %body2_style;
        background-color: $white;
        border: none !important;
        font-size: 14px;
        font-weight: 700;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        padding: 8px 0;
    }
}
.user_tray__content {
    height: inherit;
}
.email {
    color: rgb(174, 174, 178);
    font-weight: normal;
    padding-bottom: 0px;
}
.user_tray__devider {
    margin: 24px;
    border-top: 1px solid $grey_alabaster;
}
.user_tray__actions_logout {
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
}
.user_tray__actions_feedback_company {
    padding-top: 32px;
    justify-content: center;
    color: rgb(199, 199, 204);
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    font-family: "Open Sans", sans-serif;
}
</style>
