<template>
    <div class="user_header_button" @click="toggleAccountSwitcher">
        <AccountImage :size="28" :initial="initial" />
        <font-awesome-icon
            class="user_header_button__icon"
            :class="{
                'user_header_button__icon--flipped': trayOpenPercentage > 0,
            }"
            :icon="['fas', 'chevron-down']"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";
import { vueWindowSizeMixin } from "vue-window-size";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import animateXtoY from "@feedbackcompany/feedback-company-vue-components/src/helpers/animationHelper";
import AccountImage from "@/components/Global/Atoms/AccountImage.vue";

library.add(faChevronDown);

export default {
    name: "UserHeaderButton",
    mixins: [vueWindowSizeMixin],
    components: {
        FontAwesomeIcon,
        AccountImage,
    },
    computed: {
        ...mapState({
            trayOpenPercentage: (state) => state.AccountStore.logoutTrayOpenPercentage,
        }),
        initial() {
            const { user } = this.$store.state.AccountStore;
            return user.firstName && user.lastName ? user.firstName[0] : user.username[0];
        },
    },
    methods: {
        toggleAccountSwitcher() {
            if (this.trayOpenPercentage === 1) {
                this.closeAccountSwitcher();
            } else {
                this.openAccountSwitcher();
            }
        },
        closeAccountSwitcher() {
            if (window.innerWidth < 1001) {
                animateXtoY({
                    x: this.trayOpenPercentage,
                    y: 0,
                    time: 200,
                    easeName: "easeOutQuad",
                    executable: ({ setTo }) => {
                        this.$store.commit("AccountStore/logoutTrayOpenPercentage", {
                            openPercentage: setTo,
                        });
                    },
                });
            } else {
                this.$store.commit("AccountStore/logoutTrayOpenPercentage", {
                    openPercentage: 0,
                });
            }
        },
        openAccountSwitcher() {
            if (window.innerWidth < 1001) {
                animateXtoY({
                    x: this.trayOpenPercentage,
                    y: 1,
                    time: 200,
                    easeName: "easeOutQuad",
                    executable: ({ setTo }) => {
                        this.$store.commit("AccountStore/logoutTrayOpenPercentage", {
                            openPercentage: setTo,
                        });
                    },
                });
            } else {
                this.$store.commit("AccountStore/logoutTrayOpenPercentage", {
                    openPercentage: 1,
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.user_header_button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 49px;
    &:hover {
        cursor: pointer;
        .user_header_button__icon {
            @media (min-width: 1001px) {
                font-size: 16px;
                transition: all 0.3s;
                transform: rotateZ(180deg);
            }
        }
    }
    z-index: 9;
}
.user_header_button__icon {
    color: $grey_mineshaft;
    flex-shrink: 0;
    font-size: 14px;
    margin-left: 8px;
}
.user_header_button__icon--flipped {
    transform: rotateZ(-180deg);
}
</style>
