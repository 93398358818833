<template>
    <Popup class="account_switch_popup" :closeAllowed="false">
        <h1 class="headline5 account_switch_popup__title">
            {{ $t("cp__popups__account_switch__title") }}
        </h1>
        <p class="body1">{{ popupText }}</p>
        <div class="account_switch_popup__buttons">
            <Button v-if="swappingAllowed" @click.native="stay" buttonStyle="white">
                {{ stayButtonLabel }}
            </Button>
            <Button v-if="swappingAllowed" @click.native="proceed">
                {{ proceedButtonLabel }}
            </Button>
            <Button v-if="!swappingAllowed" @click.native="reload">
                {{ $t("cp__popups__account_switch__reload") }}
            </Button>
        </div>
    </Popup>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Popup from "@feedbackcompany/feedback-company-vue-components/src/components/organisms/Popup.vue";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";

export default {
    name: "AccountSwitchPopup",
    data() {
        return {
            swapToShop: JSON.parse(localStorage.activeShop),
        };
    },
    components: {
        Popup,
        Button,
    },
    computed: {
        ...mapGetters({
            activeAccount: "AccountStore/activeAccount",
            accessibleAccounts: "AccountStore/accessibleAccounts",
            accountName: "AccountStore/accountName",
        }),
        popupText() {
            if (!this.swappingAllowed) {
                return this.$t("cp__popups__account_switch__content_swap_not_allowed", {
                    stayShopName: this.accountName,
                    proceedShopName: this.swapToShop.name,
                });
            }

            return this.$t("cp__popups__account_switch__content", {
                stayShopName: this.accountName,
                proceedShopName: this.swapToShop.name,
            });
        },
        stayButtonLabel() {
            return this.$t("cp__popups__account_switch__stay_button", {
                stayShopName: this.accountName,
            });
        },
        proceedButtonLabel() {
            return this.$t("cp__popups__account_switch__proceed_button", {
                proceedShopName: this.swapToShop.name,
            });
        },
        swappingAllowed() {
            let swappingAllowed = false;
            this.accessibleAccounts.forEach((accessibleAccount) => {
                if (accessibleAccount.id === this.swapToShop.externalId) {
                    swappingAllowed = true;
                }
            });
            return swappingAllowed;
        },
    },
    methods: {
        ...mapActions({
            switchAccount: "AccountStore/switchAccount",
        }),
        stay() {
            this.switchAccount({
                uuid: this.activeAccount.externalUuid,
                executeOnAccountSwitch: false,
                apolloClient: this.$apollo,
            });
        },
        proceed() {
            this.switchAccount({
                uuid: this.swapToShop.externalUuid,
                executeOnAccountSwitch: true,
                apolloClient: this.$apollo,
            });
        },
        reload() {
            window.location.reload();
        },
    },
    mounted() {
        this.swapToShop = JSON.parse(localStorage.activeShop);
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.account_switch_popup__title {
    margin: 12px 0 0 0;
}
.account_switch_popup__buttons {
    display: flex;
    justify-content: flex-end;
    button {
        margin-left: 8px;
    }
}
</style>
