import Vue from "vue";
import * as Sentry from "@sentry/vue";
import requestURLBuilder from "./requestURLBuilder";
import { responseHandler, errorHandler } from "./httpRequestHandlers";

export default ({ url, urlParams, noAuthHeader }) =>
    new Promise((resolve, reject) => {
        const headers = { "X-Shop-Uuid": localStorage.getItem("accountUuid") };
        if (!noAuthHeader) {
            if (Vue.prototype.store.state.AccountStore.keycloak.isTokenExpired(5)) {
                Vue.prototype.store.state.AccountStore.keycloak.logout();
            }
            headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
        }

        // Build the URL.
        const requestUrl = requestURLBuilder({ url, urlParams });
        fetch(requestUrl, {
            method: "GET",
            credentials: "include",
            headers,
        })
            .then(async (response) => responseHandler(response))
            .then((response) => resolve(response))
            .catch(async (error) => {
                Sentry.captureException(error);
                reject(await errorHandler(error));
            });
    });
