import Vue from "vue";
import Router from "vue-router";
import { intersection } from "lodash/array";
import store from "./store";

const LegacyPortal = () => import(/* webpackChunkName: "MainContent" */ "@/views/LegacyPortal.vue");
const Inbox = () => import(/* webpackChunkName: "MainContent" */ "@/views/Inbox.vue");
const Widgets = () => import(/* webpackChunkName: "Widgets" */ "@/views/Widgets.vue");
const InvitationsSettings = () =>
    import(/* webpackChunkName: "Invitations" */ "@/views/Settings/Invitations.vue");
const GeneralSettings = () =>
    import(/* webpackChunkName: "GeneralSettings" */ "@/views/Settings/GeneralSettings.vue");
const ScheduledReports = () =>
    import(/* webpackChunkName: "ScheduledReports" */ "@/views/ScheduledReports.vue");
const DownloadReports = () =>
    import(/* webpackChunkName: "DownloadReports" */ "@/views/DownloadReports.vue");
const ReviewApiSettings = () =>
    import(/* webpackChunkName: "ReviewApiSettings" */ "@/views/Settings/ReviewApiSettings.vue");
const ResearchApiSettings = () =>
    import(
        /* webpackChunkName: "ResearchApiSettings" */ "@/views/Settings/ResearchApiSettings.vue"
    );
const IntegrationServiceApiSettings = () =>
    import(
        /* webpackChunkName: "IntegrationServiceApiSettings" */ "@/views/Settings/IntegrationServiceApiSettings.vue"
    );
const CompanySettingsPage = () =>
    import(
        /* webpackChunkName: "CompanySettingsPage" */ "@/views/Settings/CompanySettingsPage.vue"
    );
const Home = () => import(/* webpackChunkName: "Dashboard" */ "@/views/Home.vue");
const ResearchInvitations = () =>
    import(/* webpackChunkName: "ResearchInvitations" */ "@/views/ResearchInvitations.vue");
const DashboardsBridge = () =>
    import(/* webpackChunkName: "DashboardsBridge" */ "@/views/DashboardsBridge.vue");
const ResponseOverviewBridge = () =>
    import(/* webpackChunkName: "ResponseOverviewBridge" */ "@/views/ResponseOverviewBridge.vue");
const ScheduleReport = () =>
    import(/* webpackChunkName: "ScheduleReport" */ "@/views/ScheduleReport.vue");
const ForbiddenError = () =>
    import(/* webpackChunkName: "ForbiddenError" */ "@/components/Error/ForbiddenError.vue");
const ServerError = () =>
    import(/* webpackChunkName: "ServerError" */ "@/components/Error/ServerError.vue");
const WidgetSettings = () =>
    import(/* webpackChunkName: "WidgetSettings" */ "@/views/Settings/WidgetSettings.vue");
const CustomiseAnimatedWidget = () => import("@/views/CustomiseAnimatedWidget.vue");

Vue.use(Router);

const inboxPermissions = ["APPRECIATION_INBOX_ACCESS"];
const inboxRoutes = [
    {
        path: "/inbox/new/",
        redirect: "/inbox/new/1",
        meta: {
            permissions: inboxPermissions,
            permissionsMatchAll: true,
        },
    },
    {
        path: "/inbox/new/:page/",
        name: "Inbox",
        component: Inbox,
        meta: {
            permissions: inboxPermissions,
            permissionsMatchAll: true,
        },
    },
    {
        path: "/inbox/new/:page/:type/:ID/",
        name: "InboxDetail",
        component: Inbox,
        meta: {
            permissions: inboxPermissions,
            permissionsMatchAll: true,
        },
    },
    {
        path: "/inbox",
        name: "LegacyInbox",
        redirect: "/inbox/new",
        meta: {
            permissions: inboxPermissions,
            permissionsMatchAll: true,
        },
    },
    {
        path: "/inbox/product-reviews",
        name: "LegacyInboxDetailProductReviews",
        beforeEnter(to, from, next) {
            next({ path: "/inbox/new/1", query: { type: "product" } });
        },
        meta: {
            permissions: inboxPermissions,
            permissionsMatchAll: true,
        },
    },
    {
        path: "/inbox/product-questions",
        name: "LegacyInboxDetailProductQuestions",
        beforeEnter(to, from, next) {
            next({ path: "/inbox/new/1", query: { type: "question" } });
        },
        meta: {
            permissions: inboxPermissions,
            permissionsMatchAll: true,
        },
    },
    {
        path: "/inbox/:reviewId",
        name: "LegacyInboxDetail",
        beforeEnter(to, from, next) {
            next({ path: "/inbox/new/1", query: { reviewId: to.params.reviewId } });
        },
        meta: {
            permissions: inboxPermissions,
            permissionsMatchAll: true,
        },
    },
];
const widgetRoutes = [
    {
        path: "/widgets",
        name: "Widgets",
        component: Widgets,
        meta: {
            permissions: [
                "APPRECIATION_COMPANY_SETTINGS",
                "APPRECIATION_WIDGETS_ACCESS",
                "EVALUATION_WIDGETS_ACCESS",
            ],
            permissionsMatchAll: false,
        },
    },
    {
        path: "/widgets/classic/settings",
        name: "Widget settings",
        component: WidgetSettings,
        meta: {
            permissions: [
                "APPRECIATION_COMPANY_SETTINGS",
                "APPRECIATION_WIDGETS_ACCESS",
                "EVALUATION_WIDGETS_ACCESS",
            ],
            permissionsMatchAll: false,
        },
    },

    {
        path: "/widgets/animated/settings",
        name: "CustomiseAnimatedWidget",
        component: CustomiseAnimatedWidget,
        meta: {
            permissions: ["EVALUATION_WIDGETS_ACCESS"],
            permissionsMatchAll: false,
        },
    },
];
const settingsRoutes = [
    {
        path: "/invitations/settings",
        name: "InvitationsSettings",
        component: InvitationsSettings,
        meta: {
            permissions: [],
            permissionsMatchAll: true,
        },
    },
    {
        path: "/general/settings",
        name: "General Settings",
        component: GeneralSettings,
        meta: {
            permissions: ["APPRECIATION_REVIEW_SETTINGS"],
            permissionsMatchAll: true,
        },
    },
    {
        path: "/review-api/settings",
        name: "Review API Settings",
        component: ReviewApiSettings,
        meta: {
            permissions: ["APPRECIATION_REVIEW_API_SETTINGS"],
            permissionsMatchAll: true,
        },
    },
    {
        path: "/research-api/settings",
        name: "Research API Settings",
        component: ResearchApiSettings,
        meta: {
            permissions: ["EVALUATION_RESEARCH_API"],
            permissionsMatchAll: true,
        },
    },
    {
        path: "/integration-api-key",
        name: "Integration API key",
        component: IntegrationServiceApiSettings,
        meta: {
            permissions: ["PORTAL_INTEGRATION_API_KEY_ACCESS"],
            permissionsMatchAll: true,
        },
    },
    {
        path: "/account",
        name: "Legacy Account",
        redirect: "/share/company-page",
    },
    {
        path: "/account/settings",
        name: "Legacy Account Settings",
        redirect: "/share/company-page",
    },
];

const shareRoutes = [
    {
        path: "/share/company-page",
        name: "Company page",
        component: CompanySettingsPage,
        meta: {
            permissions: ["APPRECIATION_COMPANY_SETTINGS"],
            permissionsMatchAll: true,
        },
    },
];

const errorRoutes = [
    {
        path: "/403",
        name: "Forbidden",
        component: ForbiddenError,
    },
    {
        path: "/500",
        name: "500",
        component: ServerError,
        props: (route) => ({
            error: route.params.error,
        }),
    },
    {
        path: "/502",
        name: "502",
        component: ServerError,
        props: (route) => ({
            error: route.params.error,
        }),
    },
    {
        path: "/503",
        name: "503",
        component: ServerError,
        props: (route) => ({
            error: route.params.error,
        }),
    },
    {
        path: "/504",
        name: "504",
        component: ServerError,
        props: (route) => ({
            error: route.params.error,
        }),
    },
];

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "Home",
            component: Home,
        },
        {
            // Legacy Appreciation dashboard route
            path: "/dashboard",
            redirect: "/",
        },
        {
            path: "/backoffice",
            redirect: "/",
        },
        ...widgetRoutes,
        ...inboxRoutes,
        ...settingsRoutes,
        ...shareRoutes,
        ...errorRoutes,
        {
            path: "/scheduled-reports",
            name: "ScheduledReports",
            component: ScheduledReports,
            meta: {
                permissions: ["EVALUATION_SCHEDULED_REPORTS_ACCESS"],
                permissionsMatchAll: true,
            },
        },
        {
            path: "/schedule-report",
            name: "ScheduleReport",
            component: ScheduleReport,
            meta: {
                permissions: ["EVALUATION_SCHEDULED_REPORTS_ACCESS"],
                permissionsMatchAll: true,
            },
        },
        {
            path: "/schedule-report/:id",
            name: "EditScheduleReport",
            component: ScheduleReport,
            meta: {
                permissions: ["EVALUATION_SCHEDULED_REPORTS_ACCESS"],
                permissionsMatchAll: true,
            },
        },
        {
            path: "/download-reports",
            name: "Download Reports",
            component: DownloadReports,
            meta: {
                permissions: [
                    "EVALUATION_CUSTOM_REPORTS_ACCESS",
                    "EVALUATION_DOWNLOAD_PDF",
                    "EVALUATION_DOWNLOAD_EXCEL",
                ],
                permissionsMatchAll: false,
            },
        },
        {
            path: "/research/invitations",
            name: "ResearchInvitations",
            component: ResearchInvitations,
            meta: {
                permissions: ["PORTAL_PREPARE_ACCESS", "PORTAL_RESEARCH_INVITATIONS"],
                permissionsMatchAll: true,
            },
        },
        {
            path: "/dashboards",
            name: "DashboardsBase",
            component: DashboardsBridge,
            meta: {
                permissions: ["EVALUATION_REPORTS_ACCESS"],
                permissionsMatchAll: true,
            },
        },
        {
            path: "/dashboards/:id",
            name: "Dashboards",
            component: DashboardsBridge,
            meta: {
                permissions: ["EVALUATION_REPORTS_ACCESS"],
                permissionsMatchAll: true,
            },
        },
        {
            path: "/response-overview",
            name: "ResponseOverview",
            component: ResponseOverviewBridge,
        },
        {
            path: "/:route",
            alias: [
                "/:route/:subroute1",
                "/:route/:subroute1/:subroute2",
                "/:route/:subroute1/:subroute2/:subroute3",
            ],
            name: "Legacy",
            component: LegacyPortal,
        },
    ],
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

const hasAccess = (to) => {
    const { permissions } = store.state.AccountStore;
    const permissionsRequiredForRoute = to.meta.permissions;

    if (!permissionsRequiredForRoute) return true;

    if (to.meta.permissionsMatchAll) {
        // Route access granted if user has all permissions required for route.
        return (
            intersection(permissions, permissionsRequiredForRoute).length ===
            permissionsRequiredForRoute.length
        );
    }

    return intersection(permissions, permissionsRequiredForRoute).length > 0;
};

router.beforeEach(async (to, from, next) => {
    // homepage is always available.
    if (to.path === "/") {
        next();
        return;
    }

    // permissions are not set (yet). Lets wait until they are.
    if (!store.state.AccountStore.permissions.length) {
        const unsubscribe = store.subscribeAction({
            before: () => {},
            after: (action) => {
                if (action.type !== "AccountStore/getCurrentUser") return;

                if (hasAccess(to)) next();
                else next("/403");

                if (unsubscribe) unsubscribe();
            },
        });

        return;
    }

    if (hasAccess(to)) next();
    else next("/403");
});

export default router;
