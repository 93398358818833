import { findIndex } from "lodash";
// This is extension is for sharing common
// functionality for forms that are build with the Stepper component
export const getMutations = () => ({
    reset(state) {
        Object.keys(state.steps).forEach((stepId) => {
            state.steps[stepId].nextStepPredicate = () => true;
            state.steps[stepId].completed = false;
            state.steps[stepId].data = null;
        });
        // eslint-disable-next-line prefer-destructuring
        state.activeStep = state.stepSequence[0].id;
    },
    activateNextStep(state) {
        const currentStepIndex = findIndex(state.stepSequence, { id: state.activeStep });
        const nextStepIndex = currentStepIndex + 1;
        if (!state.stepSequence[nextStepIndex]) return;
        state.stepSequence[currentStepIndex].completed = true;
        state.activeStep = state.stepSequence[nextStepIndex].id;
    },
    setNextStepPredicate(state, { stepId, predicate }) {
        state.steps[stepId].nextStepPredicate = predicate;
    },
    setMovingBackRequiresConfirmation(state, { stepId, predicate }) {
        state.steps[stepId].movingBackRequiresConfirmation = predicate;
    },
    // Users can only take one step forward at a time,
    // but are allowed to move back to any step they've had completed previously.
    moveBackTo(state, { stepId }) {
        // Moving to the current step? Nothing happens in this case.
        if (stepId === state.activeStep) return;
        const stepSequence = state.stepSequence.map((step) => step.id);
        // Nothing happens when an unknown stepId has been provided
        if (!stepSequence.includes(stepId)) return;
        const stepsLeftAfterMove = stepSequence.slice(stepSequence.indexOf(stepId));

        // When the activeStep is not present in stepsLeftAfterMove, we're not actually jumping back
        if (!stepsLeftAfterMove.includes(state.activeStep)) return;

        stepsLeftAfterMove.forEach((stepIdToRewind) => {
            if (stepId !== stepIdToRewind) {
                // reset state for each remaining step (excluding the one moving to).
                state.steps[stepIdToRewind].data = null;
            }
            state.steps[stepIdToRewind].completed = false;
        });

        state.activeStep = stepId;
    },
    setData(state, { stepId, data }) {
        state.steps[stepId].data = data;
    },
    setLoadingState(state, { loading }) {
        state.loading = loading;
    },
});

export const getActions = () => ({
    reset({ commit }) {
        commit("reset");
    },
    next({ commit }) {
        commit("activateNextStep");
        commit("setLoadingState", { loading: false });
    },
    moveBackTo({ commit }, { stepId }) {
        commit("moveBackTo", { stepId });
    },
});

export const getGetters = () => ({
    stepIds: (state) => state.stepIds,
    getStepById: (state) => (id) => state.steps[id],
    activeStep: (state) => state.steps[state.activeStep],
    nextStep: (state) =>
        state.stepSequence[findIndex(state.stepSequence, { id: state.activeStep }) + 1],
    previousStep: (state) =>
        state.stepSequence[findIndex(state.stepSequence, { id: state.activeStep }) - 1],
    allStepsCompleted: (state) => {
        const nextStepId = findIndex(state.stepSequence, { id: state.activeStep }) + 1;
        return typeof state.stepSequence[nextStepId] === "undefined";
    },
    nextStepIsAllowed: (state) => state.steps[state.activeStep].nextStepPredicate(),
    loading: (state) => state.loading,
});
