<template>
    <button class="language_switcher_button" @click="toggleAccountSwitcher">
        <font-awesome-icon class="language_switcher_button__icon--grey" :icon="['fal', 'globe']" />
        <span class="language_switcher__language">{{ languageName }}</span>
        <font-awesome-icon
            v-if="trayOpenPercentage === 0"
            class="language_switcher_button__icon"
            :icon="['fas', 'chevron-down']"
        />
        <font-awesome-icon
            v-else
            class="language_switcher_button__icon"
            :icon="['fas', 'chevron-up']"
        />
    </button>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faGlobe } from "@fortawesome/pro-light-svg-icons";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import animateXtoY from "@feedbackcompany/feedback-company-vue-components/src/helpers/animationHelper";

library.add(faGlobe, faChevronDown, faChevronUp);

export default {
    name: "LanguageSwitcherButton",
    components: {
        FontAwesomeIcon,
    },
    computed: {
        ...mapState({
            trayOpenPercentage: (state) => state.LanguageStore.languageTrayOpenPercentage,
        }),
        ...mapGetters({
            languageName: "LanguageStore/getLanguageName",
        }),
    },
    methods: {
        toggleAccountSwitcher() {
            if (this.trayOpenPercentage === 1) {
                this.closeAccountSwitcher();
            } else {
                this.openAccountSwitcher();
            }
        },
        closeAccountSwitcher() {
            if (window.innerWidth < 1001) {
                animateXtoY({
                    x: this.trayOpenPercentage,
                    y: 0,
                    time: 200,
                    easeName: "easeOutQuad",
                    executable: ({ setTo }) => {
                        this.$store.commit("LanguageStore/setLanguageTrayOpenPercentage", {
                            openPercentage: setTo,
                        });
                    },
                });
            } else {
                this.$store.commit("LanguageStore/setLanguageTrayOpenPercentage", {
                    openPercentage: 0,
                });
            }
        },
        openAccountSwitcher() {
            if (window.innerWidth < 1001) {
                animateXtoY({
                    x: this.trayOpenPercentage,
                    y: 1,
                    time: 200,
                    easeName: "easeOutQuad",
                    executable: ({ setTo }) => {
                        this.$store.commit("LanguageStore/setLanguageTrayOpenPercentage", {
                            openPercentage: setTo,
                        });
                    },
                });
            } else {
                this.$store.commit("LanguageStore/setLanguageTrayOpenPercentage", {
                    openPercentage: 1,
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.language_switcher_button {
    @extend %body2_style;
    background: rgba($white, 0.15);
    border: 0px;
    border-radius: 4px;
    outline: 0;
    height: 36px;
    &:hover {
        cursor: pointer;
        .language_switcher_button__icon {
            @media (min-width: 1001px) {
                font-size: 16px;
                transition: all 0.3s;
                transform: rotateZ(180deg);
            }
        }
    }
}
.language_switcher_button__icon--grey {
    color: $grey_french;
}
.language_switcher__language {
    padding: 0px 8px;
}
.language_switcher_button__icon {
    width: 16px;
}
</style>
