<template>
    <InboxItemBase v-bind="$props">
        <template v-slot:titleStrong>
            <span :title="productName">{{ productName }}</span>
        </template>

        <template v-slot:icons>
            <font-awesome-icon
                v-if="answered"
                :title="$t('cp__inbox__status__answered')"
                class="inbox_item__answered_status_icon inbox_item__answered_status_icon--answered"
                :icon="['fas', 'comment-alt-check']"
            />
            <font-awesome-icon
                v-else
                :title="$t('cp__inbox__status__not_answered')"
                class="inbox_item__answered_status_icon inbox_item__answered_status_icon--unanswered"
                transform="shrink-9 up-1.5"
                :mask="['fas', 'comment-alt']"
                :icon="['fas', 'question']"
            />
            <font-awesome-icon
                v-if="!published && answered"
                :title="$t('cp__inbox_item__unpublished_title')"
                class="inbox_item__published_status_icon"
                :icon="['fas', 'eye-slash']"
            />
            <font-awesome-icon
                v-if="hasUploadedImage"
                :title="$t('cp__inbox__filter__has__uploaded_image')"
                class="inbox_item__image_icon"
                :icon="['far', 'image']"
            />
        </template>
    </InboxItemBase>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faCommentAltCheck,
    faCommentAlt,
    faQuestion,
    faEyeSlash,
} from "@fortawesome/pro-solid-svg-icons";
import { faImage } from "@fortawesome/pro-regular-svg-icons";
import { baseProps, default as InboxItemBase } from "./InboxItemBase.vue";

library.add(faCommentAltCheck, faCommentAlt, faQuestion, faEyeSlash, faImage);

export default {
    name: "InboxItemProductQuestion",
    components: {
        InboxItemBase,
        FontAwesomeIcon,
    },
    props: {
        ...baseProps,
        productName: {
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.inbox_item__published_status_icon,
.inbox_item__answered_status_icon {
    color: $grey_mid;
    font-size: 1rem;
    margin-right: 12px;
}
.inbox_item__answered_status_icon--answered {
    color: $green;
}
.inbox_item__answered_status_icon--unanswered {
    color: $red;
}
.inbox_item__image_icon {
    color: $grey_mid;
    margin-right: 12px;
}
</style>
