/* eslint-disable class-methods-use-this */
import { dataApiClient } from "@/helpers/evaluation/apiclients";

export default class QueryService {
    getDatabaseStructure() {
        return dataApiClient.get("/database-structure");
    }

    getSqlQuery(jsonQuery) {
        return dataApiClient.post("/native-sql-query", jsonQuery);
    }

    getQueryColumns(query) {
        return dataApiClient.post("/query-columns", query);
    }
}
