import {
    getActions,
    getGetters,
    getMutations,
} from "@/stores/store_extensions/StepperStoreExtension";

const SELECT_REPORT_TYPE = "SELECT_REPORT_TYPE";
const SELECT_CUSTOM_REPORT = "SELECT_CUSTOM_REPORT";
const SCHEDULE_DATES = "SCHEDULE_DATES";
const SELECT_TYPE = "SELECT_TYPE";
const DOWNLOAD = "DOWNLOAD";
const SELECT_QUESTIONNAIRE = "SELECT_QUESTIONNAIRE";

const stepSelectReportType = {
    id: SELECT_REPORT_TYPE,
    label: "cp__research_invitations__step__select_report_type",
    icon: ["fal", "copy"],
    nextStepPredicate: () => true,
    movingBackRequiresConfirmation: () => false,
    completed: false,
    data: null,
};
const stepSelectQuestionnaire = {
    id: SELECT_QUESTIONNAIRE,
    label: "cp__research_invitations__step__select_questionaire",
    icon: ["fal", "clipboard-list"],
    nextStepPredicate: () => true,
    movingBackRequiresConfirmation: () => false,
    completed: false,
    data: null,
};
const stepScheduleDates = {
    id: SCHEDULE_DATES,
    label: "cp__research_invitations__step__schedule_dates",
    icon: ["fal", "calendar-day"],
    nextStepPredicate: () => true,
    movingBackRequiresConfirmation: () => false,
    completed: false,
    data: { start: null, end: null },
};
const stepSelectType = {
    id: SELECT_TYPE,
    label: "cp__questionnaire_reports__select_type__step_title",
    icon: ["fal", "cloud-download"],
    nextStepPredicate: () => true,
    movingBackRequiresConfirmation: () => false,
    completed: false,
    data: null,
};
const stepDownload = {
    id: DOWNLOAD,
    label: "cp__questionnaire_reports__download__step_title",
    icon: ["fas", "check-circle"],
    nextStepPredicate: () => true,
    movingBackRequiresConfirmation: () => false,
    completed: false,
    data: null,
};
const stepSelectCustomReport = {
    id: SELECT_CUSTOM_REPORT,
    label: "cp__research_invitations__step__select_custom_report",
    icon: ["fal", "clipboard-list"],
    nextStepPredicate: () => true,
    movingBackRequiresConfirmation: () => false,
    completed: false,
    data: null,
};

export default {
    namespaced: true,
    state: {
        hasCustomReports: false,
        hasPDFAccess: false,
        hasExcelAccess: false,
        customReports: [],
        stepIds: {
            SELECT_REPORT_TYPE,
            SELECT_CUSTOM_REPORT,
            SCHEDULE_DATES,
            SELECT_TYPE,
            DOWNLOAD,
            SELECT_QUESTIONNAIRE,
        },
        /**
         * Steps are an object that need to adhere to the following format:
         *
         * const step: {
         *      id - Unique constant that functions as the property name within the steps object literal.
         *      label - i18n label.
         *      icon - Fontawesome icon in array notation.
         *      nextStepPredicate - Closure that needs to returns true or false.
         *          Used for step content to communicate whether all requirements have been
         *          met to move on to the next step
         *      movingBackRequiresConfirmation - when this flag is true, the user must be presented
         *          with a confirmation dialogue to before the steps' data is reset and moves back.
         *      completed - flag to indicate the completion of this step.
         *      data - Step specific state such as form data.
         * }
         */
        steps: {
            SELECT_REPORT_TYPE: stepSelectReportType,
            SELECT_QUESTIONNAIRE: stepSelectQuestionnaire,
            SELECT_CUSTOM_REPORT: stepSelectCustomReport,
            SCHEDULE_DATES: stepScheduleDates,
            SELECT_TYPE: stepSelectType,
            DOWNLOAD: stepDownload,
        },
        stepSequence: [],
        activeStep: null,
    },
    mutations: {
        ...getMutations(),
        initStore(state, { hasCustomReports, hasPDFAccess, hasExcelAccess }) {
            state.hasCustomReports = hasCustomReports;
            state.hasPDFAccess = hasPDFAccess;
            state.hasExcelAccess = hasExcelAccess;
            if (hasCustomReports && (hasPDFAccess || hasExcelAccess)) {
                const steps = [];
                steps.push(stepSelectReportType);
                steps.push(stepSelectQuestionnaire);
                steps.push(stepScheduleDates);
                steps.push(stepSelectType);
                steps.push(stepDownload);

                state.activeStep = SELECT_REPORT_TYPE;
                state.stepSequence = steps;
            } else if (hasPDFAccess || hasExcelAccess) {
                const steps = [];
                steps.push(stepSelectQuestionnaire);
                steps.push(stepScheduleDates);
                steps.push(stepSelectType);
                steps.push(stepDownload);

                state.activeStep = SELECT_QUESTIONNAIRE;
                state.stepSequence = steps;
            } else {
                const steps = [];
                steps.push(stepSelectCustomReport);
                steps.push(stepScheduleDates);
                steps.push(stepDownload);

                state.activeStep = SELECT_CUSTOM_REPORT;
                state.steps.SELECT_REPORT_TYPE.data = { type: "custom" };
                state.stepSequence = steps;
            }
        },
        activateCustomReportsMode(state) {
            if (!state.hasCustomReports) {
                throw Error(
                    '[FC Error]: This account has no custom reports. Could not manage mode. Start with "initStore" mutation instead.'
                );
            }
            const newSteps = [];
            newSteps.push(stepSelectReportType);
            newSteps.push(stepSelectCustomReport);
            newSteps.push(stepScheduleDates);
            newSteps.push(stepDownload);

            state.stepSequence = newSteps;
        },
        activateQuestionnaireMode(state) {
            if (!state.hasCustomReports) {
                throw Error(
                    '[FC Error]: This account has no custom reports. Could not manage mode. Start with "initStore" mutation instead.'
                );
            }

            const newSteps = [];
            newSteps.push(stepSelectReportType);
            newSteps.push(stepSelectQuestionnaire);
            newSteps.push(stepScheduleDates);
            newSteps.push(stepSelectType);
            newSteps.push(stepDownload);

            state.stepSequence = newSteps;
        },
    },
    actions: {
        ...getActions(),
        reset({ state, commit }) {
            commit("reset");
            commit("initStore", {
                hasCustomReports: state.hasCustomReports,
                hasPDFAccess: state.hasPDFAccess,
                hasExcelAccess: state.hasExcelAccess,
            });
        },
    },
    getters: {
        ...getGetters(),
        hasPDFAccess: (state) => state.hasPDFAccess,
        hasExcelAccess: (state) => state.hasExcelAccess,
    },
};
