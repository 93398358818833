// Remove Empty Values from Object
// eslint-disable-next-line func-names
export default function (object) {
    const filteredObject = { ...object };
    Object.keys(filteredObject).forEach((key) => {
        if (
            filteredObject[key] === null ||
            filteredObject[key] === undefined ||
            filteredObject[key].length === 0
        ) {
            delete filteredObject[key];
        }
    });
    return filteredObject;
}
