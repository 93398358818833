import deepEqual from "deep-equal";
import navigationStructure from "@/data/main-navigation-structure.yaml";

// eslint-disable-next-line func-names
export default function (router) {
    const { currentRoute } = router;

    if (currentRoute.name === "InvitationsSettings") return "config_invitation__title";
    if (currentRoute.name === "Dashboards") return "cp__nav__dashboards";

    let locationName = "";
    Object.keys(navigationStructure).forEach((itemKey) => {
        const item = navigationStructure[itemKey];
        if (item.link) {
            const matchedRoute = router.resolve(item.link);
            if (deepEqual(matchedRoute.route, currentRoute)) {
                locationName = item.label_key;
            }
        } else {
            item.sub_items.forEach((childElement) => {
                const matchedRoute = router.resolve(childElement.link);
                if (deepEqual(matchedRoute.route, currentRoute)) {
                    locationName = childElement.label_key;
                }
            });
        }
    });

    return locationName;
}
